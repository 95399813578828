import Billing from './Billing'
import Clients from './Clients'
import Home from './Home'
import Login from './Login'
import Users from './Users'
import WorkJournal from './WorkJournal'

const urls = {
    billing: '/billing',
    clients: '/clients',
    home: '/home',
    login: '/',
    users: '/users',
    workJournal: '/work-journal'
}

export {
    Billing,
    Clients,
    Home,
    Login,
    Users,
    WorkJournal,
    urls,
}