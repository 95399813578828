import { useEffect, useMemo, useState } from 'react'
import { Endpoints } from '../Constants'
import { BarChart, BaseButton, ButtonGroup, Drawer, Dropdown, Input, PaymentStatus, TextArea } from 'components'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { useSnackbar } from 'notistack'
import { InsightsRounded, SegmentRounded } from '@mui/icons-material'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getInvoices, setInvoiceList } from 'store/slices/invoices'
import { getClients } from 'store/slices/clients'
import { Client, Project, Invoice, TimeLog, Notification } from 'types'
import { ApiHelper, AuthUtility, Helpers } from 'utilities'
import { v4 as uuid4 } from 'uuid'
import * as Yup from 'yup'
import { InvoiceForm } from 'components/forms'

const DEFAULT_NOTES = 'We hope this email finds you well! Below is an invoice for our recent work together.'

const InvoiceSchema = Yup.object().shape({
  // idclient: Yup.number().min(1, 'Client is required').required('Required'),
  // idproject: Yup.number().min(1, 'Project is required').required('Required'),
  additionalemails: Yup.string(),
  duedate: Yup.string().required('Required'),
  invoiceperiod: Yup.string(),
  items: Yup.array(), //TODO: Validate items so the form can be accurate
  notes: Yup.string(),
  payperiod: Yup.number().min(0, 'No Negatives').required('Required'),
  paymenturl: Yup.string().url('Invalid'),
});

function Billing() {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const clientList = useAppSelector(getClients)
  const invoiceList = useAppSelector(getInvoices)
  const invoiceTotalsByMonth = useMemo(() => Helpers.GroupInvoicesByMonth(invoiceList), [invoiceList.length])
  const [projectList, setProjectList] = useState<Project[]>()
  const [filteredProjects, setFilteredProjects] = useState<Project[]>()
  const [invoiceDrawerOpen, toggleInvoiceDrawer] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<Client | null>()
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>()
  const [selectedProject, setSelectedProject] = useState<Project | null>()
  const [autoInvoices, setAutoInvoices] = useState<Invoice[]>([])
  const [totalDue, setTotalDue] = useState<number>(0)
  const [totalYtd, setTotalYtd] = useState<number>(0)
  const [totalTtm, setTotalTtm] = useState<number>(0)

  const loadData = async () => {
    let signedIn = await AuthUtility.IsSignedIn();
    if (!signedIn) {
      let notification = new Notification('error', 'Auth is expired. Please sign in again.')
      enqueueSnackbar(notification.get());
      return;
    }

    // Calculate totals
    if (invoiceList.length > 0) {
      const ytdCutoff = DateTime.now().set({ month: 1, day: 1 }).toFormat('yyyy-MM-dd')
      const ttmCutoff = DateTime.now().set({ year: DateTime.now().year - 1 }).toFormat('yyyy-MM-dd')
      let due = 0
      let ytd = 0
      let ttm = 0
      for (let invoice of invoiceList) {
        const amount = parseFloat(invoice.amount.toString())
        if (invoice.status == 'Due') {
          due += amount
        }

        if (invoice.duedate >= ytdCutoff && invoice.status == 'Paid') {
          ytd += amount
        }

        if (invoice.duedate >= ttmCutoff && invoice.status == 'Paid') {
          ttm += amount
        }
      }

      setTotalDue(due)
      setTotalYtd(ytd)
      setTotalTtm(ttm)

      // Load Projects
      let projectsResponse: any = await ApiHelper.get(Endpoints.Projects)

      if (projectsResponse.success) {
        setProjectList(projectsResponse.data)
        setFilteredProjects(projectsResponse.data)
      }
    }
  }

  const saveInvoice = async (values: Invoice) => {
    if (!values.idclient && !selectedClient) {
      let notification = new Notification('error', 'Client is required.')
      enqueueSnackbar(notification.get())
      return
    }
    if (values.items.length < 1) {
      let notification = new Notification('error', 'Invoice items are required.');
      enqueueSnackbar(notification.get())
      return
    }
    if (values.paymenturl && values.paymenturl.startsWith('http://')) {
      let notification = new Notification('error', 'Payment links should probably be https://')
      enqueueSnackbar(notification.get())
      return
    }
    if (!window.confirm('Are you sure that you want to create and send this invoice?')) {
      let notification = new Notification('warn', 'Cancelled.')
      enqueueSnackbar(notification.get())
      return
    }

    const amount = values.items.reduce((acc, current) => acc + current.amount, 0)

    const invoice: Partial<Invoice> = {
      idclient: values.idclient || selectedClient!.idclient,
      additionalemails: selectedClient?.additionalemails || values.additionalemails,
      amount,
      client: null,
      duedate: values.duedate || Helpers.TodaysDate(),
      invoiceperiod: values.invoiceperiod,
      items: values.items,
      notes: values.notes || '',
      payperiod: values.payperiod || 0,
      status: 'Due',
      paymenturl: values.paymenturl,
    }

    let response: any = await ApiHelper.put(Endpoints.Invoices, invoice);

    if (response.success) {
      let notification = new Notification('success', 'The invoice was created!');
      enqueueSnackbar(notification.get());
      setSelectedClient(null);
      setSelectedProject(null);
      toggleInvoiceDrawer(false);
      let newInvoice = { ...invoice, idinvoice: response.data.id } as Invoice
      let list = [newInvoice, ...invoiceList]
      dispatch(setInvoiceList(list))
    } else {
      let notification = new Notification('error', 'Failed to create the invoice. Check the console.');
      enqueueSnackbar(notification.get());
      console.error(response);
    }
  };

  const resendInvoice = async () => {
    if (!window.confirm('Are you sure? This will email the client.')) {
      return;
    }

    let response: any = await ApiHelper.post(Endpoints.ResendInvoice, { idinvoice: selectedInvoice?.idinvoice })
    if (response.success) {
      let notification = new Notification('success', `Resent Invoice ${selectedInvoice?.idinvoice}`);
      enqueueSnackbar(notification.get());
    } else {
      let notification = new Notification('error', 'Unable to resend invoice.');
      enqueueSnackbar(notification.get());
    }
  };

  const sendRevisedInvoice = async () => {
    if (!window.confirm('Are you sure? This will email the client.')) {
      return;
    }

    let response: any = await ApiHelper.post(Endpoints.SendRevisedInvoice, { idinvoice: selectedInvoice?.idinvoice })
    if (response.success) {
      let notification = new Notification('success', `Sent Revised Invoice ${selectedInvoice?.idinvoice}`);
      enqueueSnackbar(notification.get());
    } else {
      let notification = new Notification('error', 'Unable to send revised invoice.');
      enqueueSnackbar(notification.get());
    }
  };

  const editInvoiceStatus = async (status: string) => {
    if (!window.confirm('Are you sure? This will email the client.')) {
      let notification = new Notification('warn', 'Cancelled.');
      enqueueSnackbar(notification.get());
      return;
    }

    let response: any = await ApiHelper.post(Endpoints.InvoiceStatus, { id: selectedInvoice?.idinvoice, status });
    if (response.success) {
      let notification = new Notification('success', `Updated Invoice ${selectedInvoice?.idinvoice} to ${status}.`);
      enqueueSnackbar(notification.get());
      setSelectedInvoice({ ...selectedInvoice, status: status } as Invoice)
      let list = []
      for (let invoice of invoiceList) {
        if (invoice.idinvoice === selectedInvoice?.idinvoice) {
          let obj = { ...invoice }
          obj.status = status
          list.push(obj)
          continue
        }
        list.push(invoice)
      }

      dispatch(setInvoiceList(list))
    } else {
      alert(response.error.message);
    }
  };

  const onClientChanged = (client: Client) => {
    if (!client) {
      let notification = new Notification('error', 'Invalid Client');
      enqueueSnackbar(notification.get());
      return;
    }

    setSelectedClient(client);
    setFilteredProjects(projectList?.filter(el => el.idclient === client.idclient))
  };

  const onProjectChanged = (project: Project) => {
    let client = clientList?.find(el => el.idclient === project?.idclient);

    if (!project || !client) {
      let notification = new Notification('error', 'Invalid Project');
      enqueueSnackbar(notification.get());
      return;
    }

    setSelectedProject(project);
    setSelectedClient(client);
  }

  const invoiceClicked = (invoice: Invoice) => {
    let client = clientList?.find(el => el.idclient === invoice.idclient);
    let project = projectList?.find(el => el.idproject === invoice.idproject);

    setSelectedClient(client);
    setSelectedProject(project);
    setSelectedInvoice(invoice);
    toggleInvoiceDrawer(true);
  };

  const unselectInvoice = () => {
    setSelectedClient(null);
    setSelectedProject(null);
    setSelectedInvoice(null);
    toggleInvoiceDrawer(false);
    setFilteredProjects(projectList);
  }

  const getClientName = (clientId: number): string => {
    if (!clientList) {
      return '';
    }
    let client = clientList?.find(el => el.idclient === clientId);
    return client?.organization || 'N/A';
  }

  const generatePotentialInvoices = async () => {
    //get unbilled time logs for last month
    let now = DateTime.now()
    let lastMonth = now.set({ month: now.month - 1 })
    let start = lastMonth.startOf('month').toISODate();
    let end = lastMonth.endOf('month').toISODate();
    let query = `?start=${start}&end=${end}&status=Unbilled`;

    let response: any = await ApiHelper.get(Endpoints.TimeLogs + query);
    if (response.status === 404) {
      let notification = new Notification('bug', 'No time logs for last month.');
      enqueueSnackbar(notification.get());
      return;
    } else if (!response.success) {
      let notification = new Notification('error', 'Error loading time logs');
      enqueueSnackbar(notification.get());
      return;
    }

    // calculate amounts
    let invoices: Array<Invoice> = [];
    for (let timeLog of response.data as Array<TimeLog>) {
      if (!timeLog.project.billhourly) {
        continue;
      }

      let projectInvoice = invoices.filter(el => el.idproject === timeLog.idproject)[0];
      if (projectInvoice) {
        //add up the totals for each invoice as we go, so that it's ready to just click send!
        projectInvoice.items[0].amount += calculateAmount(timeLog);
        projectInvoice.timeLogs?.push(timeLog);
      } else {
        let invoice: Invoice = {
          idinvoice: 0,
          idclient: timeLog.idclient,
          idproject: timeLog.idproject,
          additionalemails: timeLog.client.additionalemails,
          amount: 0,
          client: timeLog.client,
          duedate: DateTime.now().toISODate(),
          invoiceperiod: Helpers.FormatDefaultInvoicePeriod(),
          items: [{
            id: uuid4(),
            amount: calculateAmount(timeLog),
            detail: timeLog.project.description,
            heading: timeLog.project.name,
          }],
          notes: DEFAULT_NOTES,
          payperiod: timeLog.client.defaultpayperiod,
          status: 'Due',
          timeLogs: [timeLog],
        };
        invoices.push(invoice);
      }
    }

    for (let invoice of invoices) {
      let hrs = invoice.timeLogs!.reduce((acc, current) => acc + current.duration, 0) / 3600;
      let rate = invoice.timeLogs![0].project.rate;
      invoice.items[0].detail! += `\n\n${hrs.toFixed(2)} hrs @ $${rate}/hr`;
    }

    setAutoInvoices(invoices);
  }

  const calculateAmount = (timeLog: TimeLog): number => {
    if (!timeLog) {
      return 0;
    }

    let hrs = (timeLog.duration / 3600).toFixed(2);
    return timeLog.project.rate * parseFloat(hrs);
  }

  const createAutoInvoice = async (invoice: Invoice, i: number) => {
    await saveInvoice(invoice);
    autoInvoices[i].status = 'Sent';
    setAutoInvoices(autoInvoices);
  }

  const getInvoiceRecipients = (): string => {
    if (!selectedClient) {
      return '';
    }
    let str = selectedClient.email;
    if (selectedClient.additionalemails && selectedClient.additionalemails.length > 0) {
      let parts = selectedClient.additionalemails.split(',');
      str += (', ' + parts.join(', '));
    }
    return str;
  }

  useEffect(() => {
    loadData();
  }, [invoiceList])

  const scroll = (id: string) => {
    const el = document.getElementById(id)
    el?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className='page-container' style={{ paddingTop: '2rem' }}>
      <Stack spacing={2}>
        {/* <Stack>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h1'>New Invoice</Typography>
            <ButtonGroup onClose={() => window.confirm('Are you sure you want to discard this draft? All progress will be lost.')} />
          </Box>
        </Stack>
        <Paper>
          <InvoiceForm mode='create' invoice={selectedInvoice || null} />
        </Paper> */}
        <Paper>
          <Stack spacing={2}>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h1'>Metrics</Typography>
              <Stack spacing={1} direction='row'>
                <IconButton onClick={() => scroll('list-card')}>
                  <SegmentRounded />
                </IconButton>
                <IconButton onClick={() => scroll('chart-card')}>
                  <InsightsRounded />
                </IconButton>
              </Stack>
            </Box>
            <Box>
              <Typography>Total Due: {Helpers.FormatDollars(totalDue)}</Typography>
              <Typography>Total YTD: {Helpers.FormatDollars(totalYtd)}</Typography>
              <Typography>Total TTM: {Helpers.FormatDollars(totalTtm)}</Typography>
            </Box>
          </Stack>
        </Paper>
        <Paper id='list-card'>
          <Stack spacing={2}>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h1'>Invoices</Typography>
              <Box>
                <BaseButton text='Auto Invoices' icon='money' style={{ marginRight: 20 }} onClick={generatePotentialInvoices} />
                <BaseButton text='Create Invoice' icon='add' onClick={() => toggleInvoiceDrawer(true)} />
              </Box>
            </Box>
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                  <td style={{ flex: 1 }}>Payment</td>
                  <td style={{ flex: 1 }}>Invoice Number</td>
                  <td style={{ flex: 1 }}>Invoice Date</td>
                  <td style={{ flex: 2 }}>Client</td>
                  <td style={{ flex: 1 }}>Amount</td>
                </tr>
              </thead>
              <tbody>
                {invoiceList && invoiceList.map((el) => (
                  <tr
                    key={el.idinvoice}
                    style={{ borderBottom: '0px dashed #555', display: 'flex' }}
                    onClick={() => invoiceClicked(el)}
                  >
                    <td style={{ flex: 1 }}>
                      <PaymentStatus status={el.status} />
                    </td>
                    <td style={{ flex: 1 }}>{el.idinvoice}</td>
                    <td style={{ flex: 1 }}>{Helpers.FormatDateTime(el.duedate)}</td>
                    <td style={{ flex: 2 }}>{getClientName(el.idclient)}</td>
                    <td style={{ flex: 1 }}>{Helpers.FormatDollars(el.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Stack>
        </Paper>
        <Paper id='chart-card'>
          <Stack spacing={0}>
            <Typography variant='h1'>Revenue</Typography>
            <BarChart labels={Helpers.Months} height={500} data={invoiceTotalsByMonth} />
          </Stack>
        </Paper>
      </Stack>
      <Drawer
        heading={!!selectedInvoice ? `Invoice ${selectedInvoice.idinvoice} (${selectedInvoice.status})` : 'Add Invoice'}
        open={invoiceDrawerOpen}
        toggleDrawer={unselectInvoice}
      >
        <Formik
          initialValues={selectedInvoice || {
            duedate: Helpers.TodaysDate(),
            items: [{ id: uuid4(), amount: 0, detail: '', heading: '' }],
            notes: DEFAULT_NOTES,
            payperiod: 0,
            idinvoice: 0,
            idclient: 0,
            idproject: 0,
            additionalemails: '',
            amount: 0,
            client: {},
            status: 'Due',
            invoiceperiod: Helpers.FormatDefaultInvoicePeriod(),
            paymenturl: '',
          }}
          validationSchema={InvoiceSchema}
          onSubmit={values => {
            if (selectedInvoice) {
              let notification = new Notification('error', 'Invoices can not be edited. This is a bug, probably.');
              enqueueSnackbar(notification.get());
            } else {
              saveInvoice(values);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit}>
              {!!selectedInvoice && (
                <>
                  <div className='drawer-row'>
                    <h4>{getClientName(selectedInvoice.idclient)} • {Helpers.FormatDollars(selectedInvoice.amount)}</h4>
                  </div>
                  <div className='drawer-row' style={{ border: '2px solid orange', borderRadius: 10, backgroundColor: 'lightgoldenrodyellow' }}>
                    <div className='row' style={{ padding: '1em' }}>
                      <div className='column'>
                        <p style={{ fontSize: '1.5em', }}>{String.fromCodePoint(0x26A0, 0xFE0F)}</p>
                      </div>
                      <div className='column' style={{ padding: '1em', flex: 1 }}>
                        <p style={{ fontWeight: 'bold', color: 'black' }}> This form is live and will email {getInvoiceRecipients()}.</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!selectedInvoice && (
                <div className='drawer-row'>
                  <Dropdown
                    idKey='idclient'
                    items={clientList}
                    label='Client'
                    displayKey='organization'
                    onSelect={onClientChanged}
                  />
                </div>
              )}
              <div className='drawer-row'>
                <Input name='duedate' label='Due Date' defaultValue={values.duedate} disabled={!!selectedInvoice} onBlur={handleBlur} onChange={handleChange} type='date' />
                <Input defaultValue={values.payperiod} name='payperiod' label='Pay Period' error={touched.payperiod && errors.payperiod ? errors.payperiod : ''} disabled={!!selectedInvoice} onBlur={handleBlur} onChange={handleChange} type='number' />
              </div>
              <div className='drawer-row'>
                <Input name='invoiceperiod' label='Invoice Period' fullWidth defaultValue={values.invoiceperiod} disabled={!!selectedInvoice} onBlur={handleBlur} onChange={handleChange} />
              </div>
              <div className='drawer-row'>
                <Input name='paymenturl' label='Payment Link' fullWidth defaultValue={values.paymenturl} error={touched.paymenturl && errors.paymenturl ? errors.paymenturl : ''} disabled={!!selectedInvoice} onBlur={handleBlur} onChange={handleChange} />
              </div>
              <div className='drawer-row' style={{ marginBottom: '0px' }}>
                <h6 style={{ fontSize: '1em', margin: 0, padding: 0 }}>Invoice Items</h6>
              </div>
              {!selectedInvoice &&
                <>
                  <div>
                    {values.items.map((el, itemIdx) => (
                      <div key={el.id}>
                        <div className='drawer-row' style={{ marginBottom: 0 }}>
                          <Dropdown
                            name={`items[${itemIdx}].heading`}
                            idKey='idproject'
                            items={filteredProjects}
                            label='Project'
                            displayKey='name'
                            onSelect={(item) => {
                              setFieldValue(`items[${itemIdx}].heading`, item.name);
                              setFieldValue(`items[${itemIdx}].detail`, item.description);
                            }}
                          />
                          <Input
                            name={`items[${itemIdx}].amount`}
                            label='Amount'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='number'
                          />
                        </div>
                        <div className='drawer-row' style={{ marginBottom: 0 }}>
                          <TextArea
                            name={`items[${itemIdx}].detail`}
                            defaultValue={values.items[itemIdx].detail}
                            label='Invoice Item Description'
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div className='drawer-row ' style={{ marginBottom: 20, marginTop: 10 }}>
                          <p
                            onClick={() => {
                              setFieldValue('items', values.items.filter(item => item.id !== el.id))
                            }}
                            style={{ color: '#FF0000', fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }}
                          >Delete Item</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='drawer-row right-aligned'>
                    <BaseButton text='Add Item' icon='add' onClick={() => setFieldValue(`items`, [...values.items, { id: uuid4(), amount: 0, detail: '', heading: '' }])} />
                  </div>
                </>
              }
              {selectedInvoice && selectedInvoice.idinvoice && selectedInvoice.items.map((el, i) => (
                <span key={i}>
                  <p style={{ fontWeight: 400, textDecoration: 'underline' }}>{el.heading}</p>
                  <p>{el.detail}</p>
                </span>
              ))}
              {!selectedInvoice &&
                <div className='drawer-row'>
                  <TextArea defaultValue={values.notes} name='notes' label='Notes' onBlur={handleBlur} onChange={handleChange} />
                </div>
              }
              {/* <div className='drawer-row'>
                <p>TODO: Status dropdown</p>
                <Input name='alias' label='Alias' onChange={() => { }} />
              </div> */}
              {!selectedInvoice &&
                <div className='drawer-row' style={{ justifyContent: 'end' }}>
                  <BaseButton text='Create Invoice' disabled={!isValid} icon='add' onClick={handleSubmit} type='submit' loading={isSubmitting} />
                </div>
              }
            </form>
          )}
        </Formik>
        {selectedInvoice && selectedInvoice.idinvoice && (
          <div className='drawer-row' style={{ marginTop: '1.2em' }}>
            <BaseButton text='Mark Paid' disabled={['Paid', 'Void'].includes(selectedInvoice.status)} icon='money' onClick={() => editInvoiceStatus('Paid')} />
            <BaseButton text='Send Revised' disabled={['Paid', 'Void'].includes(selectedInvoice.status)} icon='email' onClick={sendRevisedInvoice} />
            <BaseButton text='Resend' icon='email' onClick={resendInvoice} />
            <BaseButton text='Void' disabled={['Paid', 'Void'].includes(selectedInvoice.status)} icon='delete' variant='delete' onClick={() => editInvoiceStatus('Void')} />
          </div>
        )}
      </Drawer>
      <Drawer
        heading='Auto Invoices'
        open={autoInvoices.length > 0}
        toggleDrawer={() => setAutoInvoices([])}>
        {autoInvoices.map((el, i) => (
          <div key={i} style={{ border: '1px dashed grey', padding: '1em', marginBottom: '1em', overflow: 'scroll' }}>
            <div className='drawer-row' style={{ justifyContent: 'space-between' }}>
              <b>Auto Invoice {i + 1}</b>
              <p>Amount {Helpers.FormatDollars(el.items[0].amount)}</p>
            </div>
            <div className='drawer-row'>
              <p>{el.items[0].detail}</p>
            </div>
            <div className='drawer-row' style={{ justifyContent: 'space-between' }}>
              <div>
                <p>{el.timeLogs![0].client.organization}</p>
                <p>{el.timeLogs![0].project.name}</p>
              </div>
              {el.status === 'Sent'
                ? <BaseButton
                  text='Sent'
                  icon='checkmark'
                  onClick={() => {
                    let notification = new Notification('love', 'Already sent this invoice.');
                    enqueueSnackbar(notification.get());
                  }}
                  variant='green'
                />
                : <BaseButton
                  text='Send'
                  loading={el.status === 'Sending'}
                  icon={'email'}
                  onClick={() => {
                    autoInvoices[i].status = 'Sending'
                    setAutoInvoices([...autoInvoices])
                    setTimeout(() => {
                      createAutoInvoice(el, i)
                    }, 1500)
                  }}
                />
              }
            </div>
          </div>
        ))}
      </Drawer>
    </div>
  );
}

export default Billing;
