import React from 'react';
import { TextField } from '@mui/material';

interface TextAreaProps {
  defaultValue?: string
  disabled?: boolean
  error?: string
  fullWidth?: boolean
  label: string
  name?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  style?: object
  type?: 'password' | 'textarea'
  value?: string
}

function TextArea(props: TextAreaProps) {
  const label = props.error ? props.label + ': ' + props.error : props.label;

  return (
    <div style={{ display: 'block', width: '500px', maxWidth: '100%' }}>
      {
        <p className={`input-label ${!!props.error ? 'input-error-label' : ''}`}>
          {label}
        </p>
      }
      <TextField
        {...props as Omit<TextAreaProps, 'error'>}
        error={!!props.error}
        fullWidth={props.fullWidth}
        label=''
        margin='dense'
        multiline={true}
        minRows={4}
        onBlur={props.onBlur}
        onChange={props.onChange}
        style={{
          backgroundColor: 'var(--theme-background)',
          width: '100%',
        }}
        InputProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'flex-start'
          }
        }}
        value={props.defaultValue}
        variant='outlined'
      />
    </div>
  );
}

export default TextArea;