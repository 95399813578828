import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';

interface CheckboxProps {
  defaultValue?: boolean
  error?: string
  label: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLButtonElement>
}

function Checkbox(props: CheckboxProps) {
  const label = props.error ? props.label + ': ' + props.error : props.label;

  return (
    <div style={{ display: 'block' }}>
      <MuiCheckbox
        checked={props.defaultValue}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        size='medium'
        style={{
          height: 40,
          margin: 0,
          padding: 0
        }}
      />
      {
        <p className={`input-label ${!!props.error ? 'input-error-label' : ''}`}>
          {label}
        </p>
      }
    </div>
  );
}

export default Checkbox;