const baseUrl = process.env.REACT_APP_SERVER_URL

const Endpoints = {
    Clients: baseUrl + '/citadel/clients',
    ConfirmNewPassword: baseUrl + '/citadel/users/confirmpassword',
    Customer: baseUrl + '/citadel/customers',
    Invoices: baseUrl + '/citadel/invoices',
    InvoiceStatus: baseUrl + '/citadel/invoice/status',
    Profile: baseUrl + '/user/profile',
    Projects: baseUrl + '/citadel/projects',
    ResendInvoice: baseUrl + '/citadel/invoice/resend',
    SendRevisedInvoice: baseUrl + '/citadel/invoice/revised',
    TimeLogs: baseUrl + '/citadel/timelogs',
    Users: baseUrl + '/citadel/users',
    UserProjectsAssign: baseUrl + '/citadel/users/projects/assign',
    UserProjectsRemove: baseUrl + '/citadel/users/projects/remove',
}

export { Endpoints }