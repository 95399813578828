import { BarChart, Dropdown, LineChart } from 'components'
import { Endpoints } from '../Constants'
import { DateTime } from 'luxon'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getInvoices } from 'store/slices/invoices'
import { addTimeLogsToState, getTimeLogs } from 'store/slices/timeLogs'
import { ApiHelper, AuthUtility, Helpers } from 'utilities'
import { Notification, SummaryItem, TimeLog } from 'types'

const format = 'd MMMM'
const weekStart = DateTime.now().startOf('week')
const weekEnd = DateTime.now().endOf('week')

function Home() {
  const dispatch = useAppDispatch()
  const invoices = useAppSelector(getInvoices)
  const timeLogs = useAppSelector(getTimeLogs)
  const { enqueueSnackbar } = useSnackbar()
  const [filterStart, setFilterStart] = useState<DateTime>(weekStart)
  const [filterEnd, setFilterEnd] = useState<DateTime>(weekEnd)
  const [summary, setSummary] = useState<SummaryItem[] | null>()
  const [filterOptions, setFilterOptions] = useState<any[]>([])
  const [monthlySummary, setMonthlySummary] = useState<any>(null)
  const clients = useMemo(() => Helpers.DeDupClients(timeLogs), [timeLogs.length])
  const timeLogsByClient = useMemo(() => Helpers.GroupTimeLogsByClient(timeLogs), [timeLogs.length])
  const recentActivityLabels = useMemo(() => Helpers.GetLabels(monthlySummary), [monthlySummary])
  const recentActivityData = useMemo(() => Helpers.GetData(monthlySummary), [monthlySummary])

  const loadData = async (start: DateTime, end: DateTime) => {
    let signedIn = await AuthUtility.IsSignedIn()
    if (!signedIn) {
      let notification = new Notification('error', 'Auth is expired. Please sign in again.')
      enqueueSnackbar(notification.get())
      return
    }

    const timeLogs = await fetchTimeLogs(start, end)

    setFilterStart(start)
    setFilterEnd(end)

    if (timeLogs.length < 1) {
      let notification = new Notification('error', 'No time logs')
      enqueueSnackbar(notification.get())
      return
    }

    dispatch(addTimeLogsToState(timeLogs))

    let list: SummaryItem[] = []
    for (let timeLog of timeLogs) {
      let item = list.find(el => el.idproject === timeLog.idproject)
      if (item) {
        item.duration += timeLog.duration
        continue
      }

      list.push({
        idproject: timeLog.project.idproject,
        client: timeLog.client.organization,
        duration: timeLog.duration,
        name: timeLog.project.name,
      })
    }

    setSummary(list.sort((a, b) => b.duration - a.duration))
    let notification = new Notification('success', `Fetched time logs for ${weekStart.toISODate() === start.toISODate() ? 'this' : 'the selected'} week.`)
    enqueueSnackbar(notification.get())
  }

  const loadFilterOptions = () => {
    let options = [{
      id: weekStart.toISO(),
      label: `${weekStart.toFormat(format)} - ${weekEnd.toFormat(format)}`,
      start: weekStart.toISODate(),
      end: weekEnd.toISODate()
    }
    ]

    for (let i = 1; i < 10; i++) {
      let start = weekStart.set({ day: weekStart.day - (i * 7) })
      let end = weekEnd.set({ day: weekEnd.day - (i * 7) })

      let option = {
        id: start.toISO(),
        label: `${start.toFormat(format)} - ${end.toFormat(format)}`,
        start: start.toISODate(),
        end: end.toISODate()
      }

      options.push(option)
    }

    setFilterOptions(options)
  }

  const filterChanged = (item: any) => {
    let start = DateTime.fromISO(item.start)
    let end = DateTime.fromISO(item.end)

    loadData(start, end)
  }

  const fetchTimeLogs = async (start: DateTime, end: DateTime): Promise<TimeLog[]> => {
    let params = `?start=${start.toISODate()}&end=${end.toISODate()}`
    let response: any = await ApiHelper.get(Endpoints.TimeLogs + params)

    return response.success ? response.data as TimeLog[] : []
  }

  const loadMonthSummary = async () => {
    const start = DateTime.now().set({ day: 1 })
    const end = DateTime.now()
    const timeLogs = await fetchTimeLogs(start, end)

    let projects = []
    for (let timeLog of timeLogs) {
      let item = projects.find(el => el.idproject === timeLog.idproject)
      if (item) {
        item.duration += timeLog.duration
        continue
      }

      projects.push({
        idproject: timeLog.project.idproject,
        client: timeLog.client.organization,
        duration: timeLog.duration,
        name: timeLog.project.name,
      })
    }

    setMonthlySummary(projects.sort((a: any, b: any) => b.duration - a.duration))
  }

  useEffect(() => {
    // loadData(weekStart, weekEnd)
    loadFilterOptions()
    loadMonthSummary()
  }, [])

  return (
    <div className='page-container' style={{ paddingTop: '2rem' }}>
      <Stack spacing={2}>
        <Paper>
          <Stack spacing={2}>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography variant='h1'>{`${filterStart.toFormat(format)} - ${filterEnd.toFormat(format)}`}</Typography>
                <Typography variant='body2'>{!timeLogsByClient ? 0 : (timeLogsByClient.reduce((a, b) => { return a + b }, 0))} Total Hours</Typography>
              </Box>
              {/* <Box>
                <Typography variant='body2'>{`${filterStart.toFormat(format)} - ${filterEnd.toFormat(format)}`}</Typography>
              </Box> */}
            </Box>
            {timeLogsByClient && timeLogsByClient.length > 0 && (
              <BarChart labels={clients} height={500} data={timeLogsByClient} />
            )}
          </Stack>
        </Paper>
        <Paper>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h2'>Hours for {Helpers.CurrentMonth()}</Typography>
            <Typography variant='h2'>{!monthlySummary ? 0 : (monthlySummary.reduce((a: any, b: any) => { return a + b.duration }, 0) / 3600)} Total Hours</Typography>
          </Box>
          <Box>
            { }
            <BarChart data={recentActivityData} labels={recentActivityLabels} height={400} />
          </Box>
        </Paper>
        {/* <Paper>
          <Stack spacing={2}>
            <Typography variant='h1'>Weekly Summary</Typography>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography variant='h2'>{`${filterStart.toFormat(format)} - ${filterEnd.toFormat(format)}`}</Typography>
                <Typography variant='body2'>{!summary ? 0 : (summary.reduce((a, b) => { return a + b.duration }, 0) / 3600)} Total Hours</Typography>
              </Box>
              <Box>
                <Dropdown label='Change Weeks' items={filterOptions} displayKey='label' idKey='id' onSelect={filterChanged} />
              </Box>
            </Box>
            {!!summary && summary.map((el, i) => (
              <div key={el.idproject} className='drawer-row' style={{ padding: '1em', border: '1px solid grey' }}>
                <div className='column' style={{ width: 300 }}>
                  <b>{el.client}</b>
                  <p>{el.name}</p>
                  <p>{el.duration / 3600} hours</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  width: ((el.duration / 3600 / 168) * 100) + '%',
                  height: '100%',
                  backgroundImage: `linear-gradient(to right, var(--theme-primary) 0%, var(--theme-primary) ${((el.duration / summary[0].duration) * 100)}%, #ffffff ${((el.duration / summary[0].duration) * 100)}%)`,
                }}>
                </div>
              </div>
            ))}
          </Stack>
        </Paper> */}
        {/* <Paper>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h2'>Hours for {Helpers.CurrentMonth()}</Typography>
            <Typography variant='h2'>{!monthlySummary ? 0 : (monthlySummary.reduce((a: any, b: any) => { return a + b.duration }, 0) / 3600)} Total Hours</Typography>
          </Box>
          {!!monthlySummary && monthlySummary.map((el: any, i: number) => (
            <div key={el.idproject} className='drawer-row' style={{ padding: '1em', border: '1px solid grey' }}>
              <div className='column' style={{ width: 300 }}>
                <b>{el.client}</b>
                <p>{el.name}</p>
                <p>{el.duration / 3600} hours</p>
              </div>
            </div>
          ))}
        </Paper> */}
      </Stack>
    </div>
  )
}

export default Home
