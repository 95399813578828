import { BaseButton, Dropdown, Input, Table, TextArea } from 'components'
import { Endpoints } from 'Constants'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { Box, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getClients } from 'store/slices/clients'
import { Client, Invoice, Notification, Project, TimeLogStatus, UserRoles } from 'types'
import { v4 as uuid4 } from 'uuid'
import * as Yup from 'yup'
import { ApiHelper, Helpers } from 'utilities'
import { useState } from 'react'
import BillingStatus from 'components/chips/TimeLogStatus'

const DEFAULT_NOTES = 'We hope this email finds you well! Below is an invoice for our recent work together.'

interface FormProps {
    mode: 'create' | 'edit'
    invoice: Invoice | null
    onFormSubmitted?: () => void
}

const Schema = Yup.object().shape({
})

const Form = ({ mode, invoice, onFormSubmitted }: FormProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const clientList = useAppSelector(getClients)
    const [projectList, setProjectList] = useState<Project[]>()
    const [filteredProjects, setFilteredProjects] = useState<Project[]>()
    const [selectedClient, setSelectedClient] = useState<Client | null>()



    const onSubmit = async (values: Partial<Invoice>) => {
        if (mode == 'edit') {
            await alert('edit')
        } else {
            await alert('create!')
        }
    }

    const onClientChanged = (client: Client) => {
        if (!client) {
            let notification = new Notification('error', 'Invalid Client');
            enqueueSnackbar(notification.get());
            return;
        }

        setSelectedClient(client);
        setFilteredProjects(projectList?.filter(el => el.idclient === client.idclient))
    };

    return (
        <Formik
            initialValues={invoice || {
                duedate: Helpers.TodaysDate(),
                items: [{ id: uuid4(), amount: 0, detail: '', heading: '' }],
                notes: DEFAULT_NOTES,
                payperiod: 0,
                idinvoice: 0,
                idclient: 0,
                idproject: 0,
                additionalemails: '',
                amount: 0,
                client: {},
                status: 'Due',
                invoiceperiod: Helpers.FormatDefaultInvoicePeriod(),
                paymenturl: '',
                timeLogs: []
            }}
            validationSchema={Schema}
            onSubmit={async (values, FormikHelpers) => { await onSubmit(values as any) }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction='row' justifyContent='space-between'>
                            <Dropdown
                                idKey='idclient'
                                items={clientList}
                                label='Client'
                                displayKey='organization'
                                onSelect={onClientChanged}
                            />
                            <Box>
                                <BaseButton text={mode == 'edit' ? 'Update' : 'Send'} icon='send' disabled={!dirty || !isValid} loading={isSubmitting} onClick={handleSubmit} type='submit' />
                            </Box>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='space-between'>
                            <Input name='invoiceperiod' label='Invoice Period' fullWidth defaultValue={values.invoiceperiod} onBlur={handleBlur} onChange={handleChange} />
                            <Input name='duedate' label='Due Date' fullWidth defaultValue={values.duedate} onBlur={handleBlur} onChange={handleChange} type='date' />
                            <Input defaultValue={values.payperiod} fullWidth name='payperiod' label='Pay Period' error={touched.payperiod && errors.payperiod ? errors.payperiod : ''} onBlur={handleBlur} onChange={handleChange} type='number' />
                            <Input name='paymenturl' label='Payment Link' fullWidth defaultValue={values.paymenturl} error={touched.paymenturl && errors.paymenturl ? errors.paymenturl : ''} onBlur={handleBlur} onChange={handleChange} />
                        </Stack>
                        <Box>
                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                <Typography variant='h2'>Invoice Items</Typography>

                                {/* TODO: This button should open a modal that lets you pick from projects for this client. Upon choosing an option, it should load the relevant time logs and do any other prep work (LLM summary of logs => invoice items?) before dismissing the modal. */}
                                <BaseButton text='Add Project' icon='add' onClick={() => setFieldValue(`items`, [...values.items, { id: uuid4(), amount: 0, detail: '', heading: '' }])} />
                            </Stack>
                        </Box>

                        {!values.items || values.items.length === 0 && (
                            <div style={{ backgroundColor: 'var(--theme-light-blue)', borderRadius: '0.25em', padding: '1em', border: '1px solid #eee', width: '100%' }}>
                                <Typography variant='subtitle2'>Add a project to begin</Typography>
                            </div>
                        )}

                        {!!values.items && values.items.length > 0 && values.items.map((el, itemIdx) => (
                            <div key={el.id} style={{ backgroundColor: 'var(--theme-light-blue)', borderRadius: '0.25em', padding: '1em', border: '1px solid #eee' }}>
                                <Stack spacing={2}>
                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='h3'>LINK</Typography>
                                        <p
                                            onClick={() => {
                                                setFieldValue('items', values.items.filter(item => item.id !== el.id))
                                            }}
                                            style={{ color: '#FF0000', fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }}
                                        >Clear Project</p>
                                    </Box>
                                    <Stack direction='row' spacing={2}>
                                        <Input
                                            name={`items[${itemIdx}].amount`}
                                            label='Total Hours'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type='number'
                                        />
                                        <Input
                                            name={`items[${itemIdx}].amount`}
                                            label='Amount'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type='number'
                                        />
                                    </Stack>
                                    <Box>
                                        <TextArea
                                            name={`items[${itemIdx}].detail`}
                                            defaultValue={values.items[itemIdx].detail}
                                            label='Invoice Item Description'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant='h4'>Time Logs</Typography>
                                        {true && (
                                            <table style={{ width: '100%' }}>
                                                <thead>
                                                    <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                                                        <td style={{ flex: 1 }}>Date</td>
                                                        <td style={{ flex: 1 }}>User</td>
                                                        <td style={{ flex: 1 }}>Client</td>
                                                        <td style={{ flex: 1 }}>Project</td>
                                                        <td style={{ flex: 0.5 }}>Hours</td>
                                                        <td style={{ flex: 3 }}>Notes</td>
                                                        <td style={{ flex: 1 }}>Status</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[{ idtimelog: -1, date: '2025-02-01', user: 'Topher Skinner', client: 'AMCS', project: 'LINK', hours: '1.5', notes: 'QA and publish updates to Termination/Transfer summary form; Sync meeting', billingstatus: TimeLogStatus.Paid }].map(el => (
                                                        <tr key={el.idtimelog} style={{ borderBottom: '0px dashed #555', display: 'flex' }} onClick={() => alert(el.date)}>
                                                            <td style={{ flex: 1 }}>{el.date}</td>
                                                            <td style={{ flex: 1 }}>{el.user}</td>
                                                            <td style={{ flex: 1 }}>{el.client}</td>
                                                            <td style={{ flex: 1 }}>{el.project}</td>
                                                            <td style={{ flex: 0.5 }}>{el.hours}</td>
                                                            <td style={{ flex: 3 }}>{el.notes}</td>
                                                            <td style={{ flex: 1 }}>
                                                                {/* TODO:
                                                                    0. API call to update status in DB
                                                                    0. Loading spinner in place while we wait
                                                                    0. Update total hours for this invoice item if necessary 
                                                                */}
                                                                <BillingStatus status={el.billingstatus} onStatusUpdate={(status) => alert('new: ' + status)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </Box>
                                </Stack>
                            </div>
                        ))}
                    </Stack>
                </form>
            )}
        </Formik>
    )
}

export default Form