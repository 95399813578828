import { ThemeOptions } from '@mui/material'
import { lime } from '@mui/material/colors'

const monospaceFonts = '"Courier New", Courier, monospace'

const theme: ThemeOptions = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                    textTransform: 'none',
                    // backgroundColor: 'pink',
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: 'var(--theme-primary)',
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                sx: {
                    p: 2,
                    borderRadius: 4,
                },
                elevation: 4,
            }
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#635BFA',
            dark: '#521afa'
        },
        secondary: {
            main: '#00D4FF'
        }
    },
    typography: {
        body1: {
            // large, desktop default
            fontWeight: '1rem',
            lineHeight: '1.666rem',
        },
        body2: {
            // medium, mobile default
            fontFamily: monospaceFonts,
            fontWeight: 600,
            lineHeight: '1.666rem',
        },
        fontFamily: [
            'Maven Pro',
            '-apple-system',
            'Roboto',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 14,
        htmlFontSize: 14,
        h1: {
            fontSize: '2rem',
            fontWeight: 900,
            textAlign: 'left',
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 600,
            textDecoration: 'underline'
        },
        subtitle1: {
            fontFamily: monospaceFonts,
            fontSize: 12,
            fontWeight: 600
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 400,
            fontStyle: 'italic'
        },
        h5: undefined,
        h6: undefined,
        caption: undefined,
        overline: undefined,
    },
}

export default theme

// Module Augmentation to support custom theme props within Typescript
// See: https://mui.com/material-ui/customization/theming/
// declare module '@mui/material/styles' {
//     interface Theme {
//         status: {
//             danger: string
//         },
//     }
//     interface ThemeOptions {
//         status?: {
//             danger?: string
//         },
//     }
// }