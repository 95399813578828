import { WarningAmberRounded } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { CurveType, LineSeriesType } from '@mui/x-charts'
import { LineChart } from '@mui/x-charts/LineChart'

interface LineChartProps {
    data: Array<any>
    height: number
    labels: Array<any>
    lineStyle?: CurveType
    width?: number
}

const Chart = ({ data, height, labels, lineStyle, width }: LineChartProps) => {
    if (data.length === 0) {
        return (
            <Stack spacing={1} direction='row' display='flex' justifyContent='center' alignItems='center'>
                <WarningAmberRounded color='error' fontSize='small' />
                <Box>
                    <Typography variant='h2'>Invalid Chart Data</Typography>
                </Box>
            </Stack>
        )
    }
    return (
        <LineChart
            xAxis={[
                {
                    scaleType: 'point',
                    data: labels
                }
            ]}
            series={data}
            height={height}
            width={width}
        />
    )
}

export default Chart