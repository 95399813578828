export interface AuthResponse {
  signedIn: boolean
  invalidLogin: boolean
  promptMfa: boolean
  promptNewPassword: boolean,
  unhandledResponse: boolean
}

export type ButtonIcons = 'add' | 'arrowLeft' | 'arrowRight' | 'checkmark' | 'close' | 'delete' | 'edit' | 'email' | 'hide' | 'money' | 'save' | 'search' | 'send'

export interface Client {
  idclient: number
  additionalemails: string
  address1: string
  address2: string
  city: string
  created: string
  defaultpayperiod: number
  email: string
  firstname: string
  lastname: string
  organization: string
  phonenumber: string
  state: string
  updated: string
  zipcode: string
}

export type DropDownItem = {
    key: string | number
    label: string
}

export interface InvoiceItem {
  id: string
  amount: number
  detail: string
  heading: string
}

export enum InvoiceStatus {
  Due = 'Due',
  Paid = 'Paid',
  PaymentSent = 'Payment Sent',
  Void = 'Void',
}

export interface Invoice {
  idinvoice: number
  idclient: number
  idproject: number
  additionalemails: string
  amount: number
  client: any
  duedate: string
  invoiceperiod: string
  items: InvoiceItem[]
  notes?: string
  paymenturl?: string
  payperiod: number
  status: string
  timeLogs?: Array<TimeLog>
}

export class Notification {
  constructor(type: Notification['type'], message: string) {
    this.message = message;
    this.type = type;
  }

  type: 'bug' | 'error' | 'fire' | 'love' | 'money' | 'success'| 'warn'
  message: string

  getEmoji = (): string => {
    switch (this.type) {
        case 'bug':
            return String.fromCodePoint(0x1FAB2);
        case 'error':
            return String.fromCodePoint(0x26D4);
        case 'fire':
            return String.fromCodePoint(0x1F525);
        case 'love':
            return String.fromCodePoint(0x1F499);
        case 'money':
            return String.fromCodePoint(0x1F4B0);
        case 'success':
            return String.fromCodePoint(0x2705);
        case 'warn':
        default:
            return String.fromCodePoint(0x26A0, 0xFE0F);
    }
  }

  get = () => {
    return this.getEmoji() + ' ' + this.message;
  }
}

export interface Project {
  idproject: number
  idclient: number
  active: boolean
  billhourly: boolean
  budget: number
  created: string
  description: string
  name: string
  rate: number
  updated: string
}

export interface SummaryItem {
  idproject: number
  client: string
  duration: number
  name: string
}

export enum TimeLogStatus {
  Unbilled = 'Unbilled',
  Invoiced = 'Invoiced',
  Paid = 'Paid', 
  FixedBid = 'Fixed Bid',
  Comped = 'Comped',
}

export interface TimeLog {
  idtimelog: number
  idclient: number
  idproject: number
  iduser: number
  billingstatus: TimeLogStatus
  created: string
  date: string
  duration: number
  notes: string
  updated: string
  client: Client
  project: Project
}

export interface User {
  iduser: number
  authId: string
  created: string
  email: string
  firstname: string
  lastname: string
  projects: Project[]
  role: UserRoles
  updated: string
}

export enum UserRoles {
  Admin = 'admin',
  Contributor = 'contributor',
}