import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'types'
import { RootState } from '../'

export interface ClientState {
    list: Client[]
}

const initialState: ClientState = {
    list: []
}

//Create slice in App State
export const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClientList: (state, action: PayloadAction<Client[]>) => {
            state.list = action.payload
        },
        resetClientStore: () => initialState,
    },
})

//Actions
export const { resetClientStore, setClientList } = slice.actions

//Selectors
export const getClients = (state: RootState) => state.clients.list

//Export Reducer
export default slice.reducer