import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../types'
import { RootState } from '..'

export interface UserState {
    currentUser: User | null,
    list: User[]
}

const initialState: UserState = {
    currentUser: null,
    list: []
}

//Create slice in App State
export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetUsersSlice: () => initialState,
        deleteUser: (state, action: PayloadAction<User>) => {
            state.list = state.list.filter(el => el.authId !== action.payload.authId)
        },
        setCurrentUser: (state, action: PayloadAction<User | null>) => {
            state.currentUser = action.payload
        },
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.list = action.payload
        },
        updateUser: (state, action: PayloadAction<User>) => {
            state.list = state.list.map(el => el.authId === action.payload.authId ? action.payload : el)
        },
    }
})

//Actions
export const { resetUsersSlice, deleteUser, setCurrentUser, setUsers, updateUser, } = userSlice.actions

//Selectors
export const getCurrentUser = (state: RootState) => state.users.currentUser
export const getUsers = (state: RootState) => state.users.list

//Export Reducer
export default userSlice.reducer