import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import VisibilityRounded from '@mui/icons-material/VisibilityRounded'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

interface PasswordInputProps {
    autoFocus?: boolean
    defaultValue?: string
    error?: string
    fullWidth?: boolean
    label?: string
    name?: string
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onChange: React.ChangeEventHandler<HTMLInputElement>
    value?: string
}

function PasswordInput(props: PasswordInputProps) {
    const [showPassword, setPasswordVisibility] = useState<boolean>(false);
    const label = props.label + (props.error ? ': ' + props.error : '');

    const toggleVisibility = () => {
        setPasswordVisibility(!showPassword);
    }
    const iconMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    }

    return (
        <div style={{ display: 'block', width: props.fullWidth ? '100%' : 150 }}>
            <p className='input-label' style={{ color: props.error ? '#FF0000' : 'var(--app-text-dark)' }}>
                {label} <span style={{ color: 'red' }}>*</span>
            </p>
            <TextField
                {...props as Omit<PasswordInputProps, 'error'>}
                autoFocus={!!props.autoFocus}
                error={!!props.error}
                fullWidth={props.fullWidth}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={toggleVisibility}
                                onMouseDown={iconMouseDown}
                            >
                                {showPassword
                                    ? <VisibilityRounded />
                                    : <VisibilityOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        display: 'flex',
                        minHeight: '100%',
                        padding: '5px',
                        alignItems: 'center',
                    }
                }}
                label=''
                margin='none'
                name={props.name || 'password'}
                onBlur={props.onBlur}
                onChange={props.onChange}
                size='small'
                style={{
                    border: '1px solid var(--theme-dark-grey)',
                    borderRadius: '0.5em',
                    minHeight: '2.5em',
                    height: '2.5em',
                    backgroundColor: 'white'
                }}
                type={showPassword ? 'text' : 'password'}
                variant='standard'
            />
        </div>
    );
}

export default PasswordInput;