import { DateTime, Duration } from 'luxon';
import { Invoice, TimeLog } from 'types/index';

const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PasswordRegex = /([a-z])([0-9])/gi; //require one letter and one digit

export const Helpers = {
  Months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  IsValidEmail: (email: any): boolean => {
    if (!email || typeof email != 'string') {
      return false;
    }

    return EmailRegex.test(email);
  },
  IsValidPassword: (password: string): boolean => {
    if (!password || password.length < 8) {
      return false;
    }

    return PasswordRegex.test(password);
  },
  //FORMATTING
  FormatDollars: (num: number | string): string => {
    if (!num || num === 0 || num === "0") {
      return "$0";
    }

    let parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts[1] && parts[1].length === 1) {
      parts[1] = parts[1] + "0";
    }

    let str = parts.join(".");
    let dollarsign = str.indexOf("$");

    if (dollarsign < 0) {
      return "$" + str;
    }

    return str;
  },
  FormatDateTime: (val: string, format: string = 'd MMM'): string => {
    let dt = DateTime.fromISO(val)
    return dt.toFormat(format)
  },
  TodaysDate: (): string => {
    let dt = DateTime.now();
    return dt.toISODate();
  },
  CurrentMonth: (): string => {
    let dt = DateTime.now();
    return dt.toFormat('MMMM yyyy');
  },
  FriendlyDate: (val: string): string => {
    if (!val) {
      return "";
    }

    let dt = new Date(val);
    return dt.toLocaleDateString();
  },
  FormatDefaultInvoicePeriod: (): string => {
    let duration = Duration.fromObject({months: 1})
    let lastMonth = DateTime.now().minus(duration)
    return lastMonth.toFormat('MMMM yyyy')
  },
  DeDupClients: (list: TimeLog[]) => {
    let clients: string[] = []
    for (let item of list) {
      if (!clients.includes(item.client.organization)) {
        clients.push(item.client.organization)
      }
    }

    return clients
  },
  GroupInvoicesByMonth: (list: Invoice[]) => {
    let totalsHash: any = {}
    for (let month of Helpers.Months) {
      totalsHash[month] = 0
      const m = DateTime.fromFormat(`${DateTime.now().year} ${month}`, 'yyyy MMMM')
      const monthStart = m.startOf('month').toISODate()
      const monthEnd = m.endOf('month').toISODate()
      for (let item of list) {
        if (item.duedate >= monthStart && item.duedate <= monthEnd) {
          totalsHash[month] += parseFloat(item.amount.toString())
        }
      }
    }

    return Object.keys(totalsHash).map(k => totalsHash[k])
  },
  GroupTimeLogsByClient: (list: TimeLog[]) => {
    let totalsHash: any = {}
    let clients: string[] = []

    for (let item of list) {
      const name = item.client.organization
      if (!clients.includes(name)) {
        clients.push(name)
        totalsHash[name] = 0
      }

      totalsHash[name] += parseFloat(item.duration.toString()) / 3600
    }

    let arr = Object.keys(totalsHash).map(k => totalsHash[k])
    return arr
  },
  GetRecentDateLabels: () => {
    let date = DateTime.now()
    let duration = new Duration()
    duration.days = 1

    let list: string[] = []
    for (let d = 0; d < 14; d++) {
      list.push(date.toFormat('m/d'))
      date.minus(duration)
    }

    list.reverse()

    return list
  },
  getClientAbbr: (client: string) => {
    switch(client) {
      case 'Simple Thesis': return 'ST'
      case 'Harmony Lab': return 'HL'
      case 'Maple Counseling': return 'Maple'
      case 'Modern Amish': return 'MA'
      default: return client
    }
  },
  getProjectAbbr: (project: string) => {
    switch(project) {
      case 'Website Management': return 'Site'
      case 'CARE Intensive': return 'CI'
      case 'Your Savant': return 'YS'
      case 'Internal Projects': return 'Internal'
      default: return project 
    }
  },
  GetLabels: (summaryObject: [{ idproject: number, name: string, client: string, duration: number }]) => {
    if (!summaryObject) {
      return ['']
    }

    let list = summaryObject.map(el => `${Helpers.getClientAbbr(el.client)} | ${Helpers.getProjectAbbr(el.name)}`)

    return list
  },
  GetData: (summaryObject: [{ idproject: number, name: string, client: string, duration: number }]) => {
    if (!summaryObject) {
      return [0]
    }

    let list = summaryObject.map(el => el.duration / 3600)

    return list
  }
};
