import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invoice } from 'types'
import { RootState } from '../'

export interface InvoiceState {
    list: Invoice[]
}

const initialState: InvoiceState = {
    list: []
}

//Create slice in App State
export const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setInvoiceList: (state, action: PayloadAction<Invoice[]>) => {
            state.list = action.payload;
        },
        resetInvoiceStore: () => initialState,
    },
});

//Actions
export const { resetInvoiceStore, setInvoiceList } = slice.actions;

//Selectors
export const getInvoices = (state: RootState) => state.invoices.list;

//Export Reducer
export default slice.reducer;