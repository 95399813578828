import React, { CSSProperties, useState } from 'react'
import { MenuItem, Select } from '@mui/material'

interface DropdownProps {
  defaultValue?: any
  disabled?: boolean
  displayKey: string
  error?: string
  fullWidth?: boolean
  idKey: string
  items: any[] | null | undefined
  label: string
  name?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onSelect: (item: any) => void
  style?: CSSProperties
}

function Dropdown(props: DropdownProps) {
  const [selected, setSelection] = useState<string>()

  const label = props.error ? props.label + ': ' + props.error : props.label

  const handleChange = (event: any) => {
    let item = event.target.value
    setSelection(item[props.displayKey])
    props.onSelect && props.onSelect(item)
  }

  return (
    <div className='column' style={{ ...props.style, width: '100%', flex: 1 }}>
      {
        <p style={{ fontSize: 14, padding: '2px 0px 0px 5px', margin: 0, color: !!props.error ? 'var(--theme-red)' : 'var(--typography-color-dark)' }}>
          {label}
        </p>
      }
      <Select
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        error={!!props.error}
        margin='dense'
        name={props.name}
        onBlur={props.onBlur}
        onChange={handleChange}
        style={{
          width: props.fullWidth ? '100%' : 200,
          backgroundColor: 'var(--theme-background)',
        }}
        value={selected}
        variant='outlined'
      >
        {props.items && props.items.map(el => (
          <MenuItem key={el[props.idKey]} value={el}>
            <p className='dropdown-item'>{el[props.displayKey]}</p>
          </MenuItem>
          // <div key={el[props.idKey]} className='dropdown-item' onClick={(e) => handleChange(el)}>
          //   <p>{el[props.displayKey]}</p>
          // </div>
        ))}
        {(!props.items || props.items.length < 1) &&
          <MenuItem disabled >No items available</MenuItem>
        }
      </Select>
    </div>
  )
}

export default Dropdown