import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeLog } from 'types'
import { RootState } from '../'
import { DateTime } from 'luxon'

export interface TimeLogsState {
    history: TimeLog[]
}

const initialState: TimeLogsState = {
    history: []
}

//Create slice in App State
export const timeLogsSlice = createSlice({
    name: 'timeLogs',
    initialState,
    reducers: {
        addTimeLogsToState: (state, action: PayloadAction<TimeLog[]>) => {
            let list:TimeLog[] = state.history.slice()

            for(let timeLog of action.payload) {
                if (list.find(el => el.idtimelog === timeLog.idtimelog)) {
                    continue
                }

                list.push(timeLog)
            }

            state.history = list.sort((a, b) => DateTime.fromISO(a.created).toMillis() - DateTime.fromISO(b.created).toMillis() )
        },
        setTimeLogHistory: (state, action: PayloadAction<TimeLog[]>) => {
            state.history = action.payload;
        },
        resetTimeLogStore: () => initialState,
    },
});

//Actions
export const { addTimeLogsToState, resetTimeLogStore, setTimeLogHistory } = timeLogsSlice.actions;

//Selectors
export const getTimeLogs = (state: RootState) => state.timeLogs.history;

//Export Reducer
export default timeLogsSlice.reducer;