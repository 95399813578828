import axios from 'axios';
import Logger from './loggingUtility';
import AuthUtility from './authUtility';

class _helper {
    constructor () {
        this.configure();
    }


    // CONFIG METHODS
    configure = () => {
        this.instance = null;

        this.instance = axios.create({
            headers: {
                'Accept': 'application/json',
                'x-st-client': 'citadel',
                'x-st-clientversion': '1.0',
                'x-st-fingerprint': this.getFingerprint()
            }
        });
    }
    reconfigure = async () => {
        let token = await this.getAuthToken();
        if (!token){
            Logger.LogError('auth token not found');
        }
        this.configure();
    }
    unconfigure = () => {
        this.instance = null;

        this.deleteAuthToken();
        this.deleteRefreshToken();

        Logger.LogWarning('API helper was unconfigured.');
    }


    // REST METHODS
    get = async (url) => {
        try{
            let options = await this.getRequestTokens();
            let res = await this.instance.get(url, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error) {
                return this.formatResponse(null, true)
            }
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.response.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    put = async (url, body, headers) => {
        try{
            let options = await this.getRequestTokens();
            if (headers){
                options = {...options.headers, headers}
            }
            let res = await this.instance.put(url, body, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    post = async (url, body, headers) => {
        try{
            let options = await this.getRequestTokens();
            if (headers){
                options = {...options.headers, headers}
            }
            let res = await this.instance.post(url, body, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            return this.formatResponse(null, error);
        }
    }
    delete = async (url) => {
        try{
            let options = await this.getRequestTokens();
            let res = await this.instance.delete(url, options);
            if (res.data.token){
                this.saveAuthToken(res.data.token);
                this.setAuthExpiry();
            }
            return this.formatResponse(res);
        }
        catch (error){
            if (!error.response){
                Logger.LogError('NON SERVER ERROR');
                Logger.LogError(error);
                return this.formatResponse(null, error);
            }

            //Handle unauthorized
            if (error.response.status === 401) {
                this.setAuthExpiry(1); //force next request to send refresh token
            }

            //Handle new auth token on errors like not found, bad request, etc.
            if (error.response && error.response.data && error.response.data.token){
                this.saveAuthToken(error.resposne.data.token);
                this.setAuthExpiry();
            }

            // return { status: error.response.status };
            return this.formatResponse(null, error);
        }
    }

    // HELPERS
    tryRefresh = async () => {
        Logger.LogError('NOT TRYING REFRESH');
        return false;
    }
    getRequestTokens = async () => {
        let options = {
            headers: {
                "x-st-authtoken": await this.getAuthToken()
            }
        };
        if (this.isAuthExpired()){
            options.headers = {...options.headers, "x-st-refreshtoken" : await this.getRefreshToken()}
        }
        return options;
    }
    formatResponse = (res, err) => {
        let response = {};
        if (err && !err.response){
            return {status: 400, success: false};
        }
        if (err && err.response){
            return {status: err.response.status, success: false}
        }
        response = {
            status: res.status,
            success: res.status === 200 || res.status === 201
        };
        if (res.data){
            response.data = res.data.data || {}
        }
        return response;
    }
    getFingerprint = () => {
        let fp =  window.localStorage.getItem('fp') || '.';
        return fp;
    }
    setFingerprint = (value) => {
        window.localStorage.setItem('fp', value);
    }
    formatFingerprint = (hash) => {
        if (!hash){
            return 'INVALID_HASH';
        }
        hash = hash.toUpperCase();
        let str = '';
        let g = '';
        let n = 0;
        for(let c of hash){
            n++;
            g += c;
            if (n === 24){
                return str += g;
            }
            if (n < 24 && g.length === 4){
                str += g + '-';
                g = '';
            }
        }
    }

    //TOKEN MANAGEMENT
    isAuthExpired = () => {
        return false;
    }
    getAuthExpiry = () => {
        return window.localStorage.getItem('authexpiry');
    }
    setAuthExpiry = (ts) => {
        window.localStorage.setItem('authexpiry', ts === undefined ? Date.now() + (3600 * 1000) : ts); //expires in 1 hour
    }
    getAuthToken = async () => {
        return await AuthUtility.GetAuthToken()
    }
    getRefreshToken = async () => {
        return await AuthUtility.GetRefreshToken()
    }
    saveAuthToken = (token) => {
        window.localStorage.setItem('authtoken', token);
    }
    saveRefreshToken = (token) => {
        window.localStorage.setItem('refreshtoken', token);
    }
    deleteAuthToken = () => {
        window.localStorage.setItem('authtoken', '');
    }
    deleteRefreshToken = () => {
        window.localStorage.setItem('refreshtoken', '');
    }
}

const ApiHelper = new _helper();
export default ApiHelper;