import {
    Box,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { FC, ReactNode } from 'react'

const NavItem: FC<{
    active: boolean,
    collapsed: boolean,
    icon: ReactNode,
    label: string,
    onClick: () => void
}> = ({ active, collapsed, icon, label, onClick }) => {

    return (
        <ListItem
            key={label}
            disablePadding
            sx={{
                display: 'flex',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    backgroundColor: active ? 'var(--theme-secondary)' : 'transparent',
                    borderRadius: collapsed ? 0 : '8px',
                    display: 'flex',
                    flex: 1,
                    mx: collapsed ? 0 : 1,
                }}
            >
                <ListItemButton
                    onClick={onClick}
                    sx={{
                        height: '3em',
                        justifyContent: collapsed ? 'initial' : 'center',
                        px: 2.5,
                        borderRadius: collapsed ? 0 : '8px',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            color: active ? 'white' : 'black',
                            minWidth: 0,
                            mr: collapsed ? 'auto' : 3,
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    {collapsed
                        ? null
                        : (
                            <ListItemText
                                primary={label}
                                sx={{
                                    opacity: collapsed ? 0 : 1,
                                }}
                            />
                        )}
                </ListItemButton>
            </Box>
        </ListItem>
    )
}

export default NavItem