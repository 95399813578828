import { Amplify, Auth } from 'aws-amplify';
import Logger from './loggingUtility';
import { AuthResponse } from 'types/index';

interface AWSAuthResponse {
    challengeName: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'NEW_PASSWORD_REQUIRED' | 'MFA_SETUP' | null
    challengeParam: any
    requiredAttributes: string[]
}

let CognitoUser: AWSAuthResponse;

const AuthUtility = {
    Initialize: async () => {
        const config = {
            mandatorySignIn: true,
            region: process.env.REACT_APP_AWS_COGNITO_REGION,
            userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
            identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
        }
        Amplify.configure({
            Auth: config
        });
    },
    IsSignedIn: async (): Promise<boolean> => {
        let session = await AuthUtility.GetCurrentUser();
        return !!session;
    },
    SignIn: async (username: string, password: string): Promise<AuthResponse> => {
        try {
            CognitoUser = await Auth.signIn(username, password);

            switch (CognitoUser.challengeName) {
                case 'SMS_MFA':
                case 'SOFTWARE_TOKEN_MFA':
                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: true,
                        promptNewPassword: false,
                        unhandledResponse: false,
                    };
                case 'NEW_PASSWORD_REQUIRED':
                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: false,
                        promptNewPassword: true,
                        unhandledResponse: false,
                    };
                case 'MFA_SETUP':
                    alert('AuthUtility - mfa setup required'); //User hasn't finished setting up TOTP method

                    return {
                        signedIn: false,
                        invalidLogin: false,
                        promptMfa: false,
                        promptNewPassword: false,
                        unhandledResponse: true,
                    };
                default:
                    break;
            }

            return {
                signedIn: true,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: false,
            }
        } catch (err: any) {
            if (err.code === 'UserNotConfirmedException') {
                console.log('UserNotConfirmedException') //User hasn't finished the sign up process
                await Auth.resendSignUp(username); //TODO: Handle this case better
            } else if (err.code === 'PasswordResetRequiredException') {
                console.error('PasswordResetRequiredException') //Password was reset in the Cognito console
                //TODO: initiate - Auth.forgotPassword(username);
                return {
                    signedIn: false,
                    invalidLogin: false,
                    promptMfa: false,
                    promptNewPassword: true,
                    unhandledResponse: false,
                }
            } else if (err.code === 'NotAuthorizedException') {
                return {
                    signedIn: false,
                    invalidLogin: true,
                    promptMfa: false,
                    promptNewPassword: false,
                    unhandledResponse: false,
                }
            } else if (err.code === 'UserNotFoundException') {
                console.log('UserNotFoundException');
                // Email doesn't exist in the Cognito user pool
            } else {
                //Non-AWS Exception
                console.log(err);
            }

            return {
                signedIn: false,
                invalidLogin: false,
                promptMfa: false,
                promptNewPassword: false,
                unhandledResponse: true,
            }
        }
    },
    ConfirmSignIn: async (mfaCode: string): Promise<AuthResponse> => {
        await Auth.confirmSignIn(CognitoUser, mfaCode, 'SMS_MFA');

        return {
            signedIn: true,
            invalidLogin: false,
            promptMfa: false,
            promptNewPassword: false,
            unhandledResponse: false,
        }
    },
    CompleteNewPassword: async (newPassword: string): Promise<AuthResponse> => {
        const user = await Auth.currentAuthenticatedUser()
        console.log('AuthUtility.CompleteNewPassword', user)
        await Auth.completeNewPassword(user, newPassword)

        return {
            signedIn: true,
            invalidLogin: false,
            promptMfa: false,
            promptNewPassword: false,
            unhandledResponse: false,
        }
    },
    SignOut: async () => {
        await Auth.signOut();
    },
    CreateUser: async (username: string, password: string): Promise<{ created: boolean, errorCode?: string }> => {
        try {
            let { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email: username
                }
            });
            return { created: true };
        } catch (error: any) {
            if (error.code) {
                return { created: false, errorCode: error.code }
            }

            return { created: false, errorCode: 'UnhandledException' }
        }
    },
    ChangeEmail: async () => {
        await Auth.currentSession(); //TODO: this
    },
    ChangePassword: async (oldPassword: string, newPassword: string): Promise<{ success: boolean, errorCode?: string }> => {
        try {
            let user = await AuthUtility.GetCurrentUser();

            await Auth.changePassword(user, oldPassword, newPassword);

            return { success: true };
        } catch (error: any) {
            if (error.code) {
                return { success: false, errorCode: error.code };
            }

            return { success: false, errorCode: 'UnhandledException' };
        }
    },
    GetCurrentUser: async () => {
        try{
            return await Auth.currentAuthenticatedUser();
        }
        catch (error) {
            return false
        }
    },
    RefreshAuth: async () => {
        //The below method automatically attempts to refresh the session when
        //access token is expired. https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
        await Auth.currentSession();
    },
    GetAuthToken: async () => {
        try{
            let session = await Auth.currentSession();
            return session.getAccessToken().getJwtToken();
        } catch (error: any) {
            Logger.LogError('Auth Utility: Error getting auth tokens from session.');
            return null;
        }
    },
    GetRefreshToken: async () => {
        let session = await Auth.currentSession();
        return session.getRefreshToken().getToken();
    }
}

export default AuthUtility;