import { AppDrawer } from 'components'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import {
    Billing,
    Clients,
    Home,
    Login,
    Users,
    WorkJournal,
    urls,
} from 'pages'
import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'style/App.css'
import themeOptions from 'style/theme'
import { AuthUtility } from 'utilities'

const lightTheme = createTheme(themeOptions)

function App() {
    useEffect(() => {
        AuthUtility.Initialize()
    }, [])

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={lightTheme}>
                <div id='layout' className='layout-wrapper'>
                    <BrowserRouter>
                        <AppDrawer />
                        <Routes>
                            <Route path={urls.billing} element={<Billing />} />
                            <Route path={urls.clients} element={<Clients />} />
                            <Route path={urls.home} element={<Home />} />
                            <Route path={urls.login} element={<Login />} />
                            <Route path={urls.users} element={<Users />} />
                            <Route path={urls.workJournal} element={<WorkJournal />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </ThemeProvider>
        </>
    )
}

export default App
