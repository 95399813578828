import { WarningAmberRounded } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'

interface BarChartProps {
    data: Array<any>
    height: number
    labels: Array<any>
    width?: number
}

const Chart = ({ data, height, labels, width }: BarChartProps) => {
    if (data.length == 0) {
        return (
            <Stack spacing={1} direction='row' display='flex' justifyContent='center' alignItems='center'>
                <WarningAmberRounded color='error' fontSize='small' />
                <Box>
                    <Typography variant='h2'>Invalid Chart Data</Typography>
                </Box>
            </Stack>
        )
    }

    return (
        <BarChart
            xAxis={[
                {
                    id: 'months',
                    data: labels,
                    scaleType: 'band',
                },
            ]}
            series={[
                { data, color: 'var(--theme-primary)' }
            ]}
            height={height}
            width={width}
        />
    )
}

export default Chart