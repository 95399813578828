import { useMemo } from 'react'

const Chip = ({ status }: { status: string }) => {
    const backgroundColor = useMemo(() => {
        switch (status) {
            case 'Due':
                return 'var(--theme-secondary)'
            case 'Paid':
                return 'var(--theme-green)'
            case 'Payment Sent':
                return 'var(--theme-yellow)'
            case 'Void':
                return 'var(--theme-light-grey)'
            default:
                return 'var(--theme-red)'
        }
    }, [status])

    const statusStr = status === 'Payment Sent' ? 'Sent' : status

    return (
        <div className='row flex-center' style={{ backgroundColor, borderRadius: '0.5rem', padding: '0.25rem' }}>
            <p style={{ fontSize: '0.75rem', fontWeight: 700 }}>{statusStr}</p>
        </div>
    )
}

export default Chip