import { urls } from '.'
import { Endpoints } from '../Constants'
import Package from '../../package.json'
import { BaseButton, Input, PasswordInput } from 'components'
import { useQuery } from 'hooks'
import { useSnackbar } from 'notistack'
import { Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import { Box, Checkbox, Link, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { resetClientStore, setClientList } from 'store/slices/clients'
import { resetInvoiceStore, setInvoiceList } from 'store/slices/invoices'
import { addTimeLogsToState, resetTimeLogStore } from 'store/slices/timeLogs'
import { resetUsersSlice, setCurrentUser, setUsers } from 'store/slices/users'
import { AuthResponse, Notification, UserRoles } from 'types'
import { ApiHelper, AuthUtility, Helpers } from 'utilities'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid').required('Required'),
  password: Yup.string().min(6, 'Too short').required('Required'),
})

const PwResetSchema = Yup.object().shape({
  confirmNewPassword: Yup.string().required('Required').when('password', {
    is: (val: string) => val && val.length > 0,
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Must match New Password'
    )
  }),
  password: Yup.string().min(12, 'Must be 12 characters').required('Required'),
})

function Login() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { enqueueSnackbar } = useSnackbar()
  const [isPasswordUpdateVisible, setPasswordUpdateVisible] = useState<boolean>(false)

  //METHODS
  const login = async (values: { email: string, password: string }): Promise<AuthResponse> => {
    let authResponse = await AuthUtility.SignIn(values.email, values.password)

    if (authResponse.signedIn) {
      onSignedIn()
      return authResponse
    } else if (authResponse.promptNewPassword) {
      return authResponse
    } 

    let notification

    if (authResponse.invalidLogin) {
      notification = new Notification('error', 'Login was invalid')
    } else if (authResponse.promptMfa) {
      notification = new Notification('bug', 'TODO: Prompt MFA')
    } else if (authResponse.unhandledResponse) {
      notification = new Notification('bug', 'AuthUtility.unhandledResponse')
    } else {
      notification = new Notification('error', 'Something went very wrong with the AuthUtility')
    }

    enqueueSnackbar(notification.get())

    return authResponse
  }

  const onSignedIn = async () => {
    // Load user profile
    let currentUserResponse: any = await ApiHelper.get(Endpoints.Profile)

    if (!currentUserResponse.success) {
      let notification = new Notification('error', 'There was a problem loading the user profile.')
      enqueueSnackbar(notification.get())
      return
    }

    dispatch(setCurrentUser(currentUserResponse.data))

    // If user is admin, load all users
    if (currentUserResponse.data.role === UserRoles.Admin) {
      let usersResponse: any = await ApiHelper.get(Endpoints.Users)
      if (!usersResponse.success) {
        let notification = new Notification('error', 'There was a problem loading Users')
        enqueueSnackbar(notification.get())
      } else {
        dispatch(setUsers(usersResponse.data))
      }
    }

    // Load clients
    const clientsResponse: any = await ApiHelper.get(Endpoints.Clients)

    if (!clientsResponse.success) {
      let notification = new Notification('error', 'There was a problem loading Clients')
      enqueueSnackbar(notification.get())
      return
    }

    dispatch(setClientList(clientsResponse.data))

    // Load invoices
    const invoiceResponse: any = await ApiHelper.get(Endpoints.Invoices)

    if (!invoiceResponse.success) {
      let notification = new Notification('error', 'There was a problem loading Invoices')
      enqueueSnackbar(notification.get())
      return
    }

    dispatch(setInvoiceList(invoiceResponse.data))

    // Load time logs
    const weekStart = DateTime.now().startOf('week')
    const weekEnd = DateTime.now().endOf('week')
    const params = `?start=${weekStart.toISODate()}&end=${weekEnd.toISODate()}`
    const timeLogResponse: any = await ApiHelper.get(Endpoints.TimeLogs + params)

    if (!timeLogResponse.success) {
      let notification = new Notification('error', 'There was a problem loading Time Logs')
      enqueueSnackbar(notification.get())
    } else {
      dispatch(addTimeLogsToState(timeLogResponse.data))
    }

    // Go to dashboard
    navigate(urls.home)
  }

  useEffect(() => {
    AuthUtility.SignOut()
    dispatch(resetClientStore())
    dispatch(resetInvoiceStore())
    dispatch(resetTimeLogStore())
    dispatch(resetUsersSlice())
  }, [])

  useEffect(() => {
    if (query.get('invite') === '1' && Helpers.IsValidEmail(query.get('email')) && !!query.get('token')) {
      acceptInvite()
    } else {
      navigate(urls.login)
    }
  }, [query.get('invite')])

  const acceptInvite = async () => {
    const response = await login({ email: query.get('email')!, password: query.get('token')! })
    if (response.promptNewPassword) {
      setPasswordUpdateVisible(true)
    }
  }

  const submitNewPassword = async (password: string) => {
    const body = {
      user: query.get('user'),
      email: query.get('email'),
      password
    }
    let response: any = await ApiHelper.post(Endpoints.ConfirmNewPassword, body)
    if (response.success) {
      await login({email: query.get('email')!, password})
    } else {
      alert(response.error.message)
    }
  }

  return (
    <div className='page-container' style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Paper style={{ width: '400px', maxWidth: '100%', overflow: 'scroll', maxHeight: '80vh', marginBottom: '2rem' }}>
        <Stack spacing={1}>
          <Box display='flex' justifyContent='center'>
            <img src='../assets/brandmark.png' height='120px' alt='Simple Thesis logo' />
          </Box>
          {isPasswordUpdateVisible && (
            <Formik
              initialValues={{ confirmNewPassword: '', password: '' }}
              validationSchema={PwResetSchema}
              onSubmit={(values) => submitNewPassword(values.confirmNewPassword)}
            >
              {({ values, touched, errors, isSubmitting, isValid, handleSubmit, handleBlur, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <p>Qwerty123_45</p>
                    <PasswordInput name='password' label='New Password' fullWidth defaultValue={values.password} error={touched.password && errors.password ? errors.password : ''} onBlur={handleBlur} onChange={handleChange} />
                    <PasswordInput name='confirmNewPassword' label='Confirm New Password' fullWidth defaultValue={values.confirmNewPassword} error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ''} onBlur={handleBlur} onChange={handleChange} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p><a href={urls.login} target='_self'>Go to login page</a></p>
                      <BaseButton text='Submit Pw' icon='arrowRight' type='submit' onClick={handleSubmit}  disabled={!isValid} loading={isSubmitting} />
                    </div>
                  </Stack>
                </form>
              )}
            </Formik>
          )}
          {!isPasswordUpdateVisible && (
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                login(values)
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                <form onSubmit={handleSubmit} className='column' style={{ width: '100%' }} >
                  <Input name='email' label='Email' defaultValue={values.email} error={touched.email && errors.email ? errors.email : ''} fullWidth={true} onChange={handleChange} onBlur={handleBlur} />
                  <div style={{ height: '1em' }}></div>
                  <PasswordInput name='password' label='Password' defaultValue={values.password} error={touched.password && errors.password ? errors.password : ''} fullWidth onChange={handleChange} onBlur={handleBlur} />
                  <BaseButton text='Login' color='primary' type='submit' onClick={handleSubmit} loading={isSubmitting} style={{ marginTop: '1.5em' }} />
                </form>
              )}
            </Formik>
          )}
        </Stack>
      </Paper>
      <p>Version {Package.version} ~ {process.env.REACT_APP_COMMIT}</p>
    </div>
  )
}

export default Login
