import React, { CSSProperties } from 'react'
import { Button, CircularProgress } from '@mui/material'
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import { ButtonIcons } from '../../types'
import { CloseRounded, ErrorRounded, SearchRounded, SendRounded } from '@mui/icons-material'

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  classes?: string
  fullWidth?: boolean
  icon?: ButtonIcons
  leftIcon?: boolean
  loading?: boolean
  onClick?: any
  text?: string
  variant?: string
}

const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const getIcon = (position: 'left' | 'right') => {
    if (!props.icon) {
      return null
    }

    if (props.loading) {
      return null
    }

    switch (props.icon) {
      case 'add':
        return <AddRoundedIcon fontSize='small' />
      case 'arrowLeft':
        return <ArrowBackIosOutlinedIcon fontSize='small' />
      case 'arrowRight':
        return <ArrowForwardIosOutlinedIcon fontSize='small' />
      case 'checkmark':
        return <CheckRoundedIcon fontSize='small' />
      case 'close':
        return <CloseRounded fontSize='small' />
      case 'delete':
        return <DeleteRoundedIcon fontSize='small' />
      case 'edit':
        return <EditRoundedIcon fontSize='small' />
      case 'email':
        return <MailOutlineRoundedIcon fontSize='small' />
      case 'hide':
        return <VisibilityOffRoundedIcon fontSize='small' />
      case 'money':
        return <AccountBalanceRoundedIcon fontSize='small' />
      case 'save':
        return <AddToPhotosRoundedIcon fontSize='small' />
      case 'search':
        return <SearchRounded fontSize='small' style={{ transform: 'scaleX(-1)' }} />
      case 'send':
        return <SendRounded fontSize='small' />
      default:
        return <ErrorRounded fontSize='small' />
    }
  }

  const getBackground = (): string => {
    if (props.disabled) {
      return '#aaa'
    }

    if (props.loading) {
      return 'var(--theme-dark-grey)'
    }

    switch (props.variant) {
      case 'delete':
        return 'var(--theme-red)'
      case 'green':
        return '#01ca01'
      case 'default':
      default:
        return 'var(--theme-primary)'
    }
  }

  return (
    <Button
      className={props.classes}
      disabled={props.disabled || props.loading}
      disableRipple={true}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      endIcon={props.leftIcon ? null : getIcon('right')}
      startIcon={props.leftIcon ? getIcon('left') : null}
      style={{
        color: 'var(--typography-color-light)',
        fontSize: '1rem',
        textTransform: 'none',
        backgroundColor: getBackground(),
        ...props.style
      }}
      variant='contained'
    >
      {props.text}
      {props.loading &&
        <CircularProgress size={25} color='primary' style={{ marginLeft: 10, color: 'white' }} />
      }
    </Button>
  )
}

export default BaseButton