import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded'
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded'
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded'
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded'
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded'
import {
    Box,
    Drawer as MuiDrawer,
    IconButton,
    List,
} from '@mui/material'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { urls } from '../../pages'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Notification, UserRoles } from '../../types'
import { AuthUtility } from '../../utilities'
import { NavItem } from '.'
import { useAppSelector } from 'store/hooks'
import * as Store from 'store/slices'

type SideMenuProps = {
    navFunction?: (url: string) => void
}

const drawerWidth = 260

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        backgroundColor: 'blue',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const AdminMenu = [
    { label: 'Dashboard', icon: <DashboardRoundedIcon />, url: urls.home, },
    { label: 'Work Journal', icon: <MoreTimeRoundedIcon />, url: urls.workJournal, },
    { label: 'Invoices', icon: <AttachMoneyRounded />, url: urls.billing, },
    { label: 'Clients', icon: <AddchartRoundedIcon />, url: urls.clients, },
    // (userRole === UserRoles.Admin) && { label: 'System Health', icon: <CloudQueueRoundedIcon />, },
    // (userRole === UserRoles.Admin) && { label: 'Stripe Info', icon: <AddShoppingCartRoundedIcon />, },
    { label: 'Users', icon: <GroupRoundedIcon />, url: urls.users, },
    { label: 'Logout', icon: <LogoutRoundedIcon />, url: urls.login, },
]

const ContractorMenu = [
    { label: 'Dashboard', icon: <DashboardRoundedIcon />, url: urls.home, },
    { label: 'Work Journal', icon: <MoreTimeRoundedIcon />, url: urls.workJournal, },
    // TODO: User profile page
    // { label: 'Account', icon: <GroupRoundedIcon />, url: urls.users, },
    { label: 'Logout', icon: <LogoutRoundedIcon />, url: urls.login, },
]

const CollapseDrawer = (props: SideMenuProps) => {
    const currentUser = useAppSelector(Store.getCurrentUser)
    const { enqueueSnackbar } = useSnackbar()
    const nav = useNavigate()
    const location = useLocation()
    const [signedIn, setSignedIn] = useState<boolean>(false)
    const [open, toggle] = useState<boolean>(true)
    const menuItems = useMemo(() => {
        if (!currentUser) {
            return []
        }
        return currentUser.role === UserRoles.Admin ? AdminMenu : ContractorMenu
    }, [currentUser])

    const navigate = (url: string | undefined) => {
        if (!url) {
            const notification = new Notification('bug', 'This is yet to be done.')
            enqueueSnackbar(notification.get())
            return
        }
        if (props.navFunction) {
            props.navFunction(url)
            return
        }

        nav(url)
    }

    const isCurrentPage = (url: string | undefined): boolean => {
        return url === location.pathname
    }

    const checkAuth = async () => {
        if (location.pathname === urls.login) {
            setSignedIn(false)
            return
        }

        let signedIn = await AuthUtility.IsSignedIn()

        if (!signedIn) {
            navigate(urls.login)
            return
        }

        setSignedIn(signedIn)
    }

    useEffect(() => {
        checkAuth()
    }, [location.pathname])

    return !signedIn
        ? null
        : (
            <Box sx={{ display: 'flex' }}>
                <Drawer variant='permanent' open={open} PaperProps={{ sx: { borderRadius: 0 } }}>
                    <Box>
                        <DrawerHeader>
                            <div className='row flex-center' style={{ margin: '2em 0px', width: '100%' }} onClick={() => navigate(urls.home)}>
                                <img src='/assets/brandmark-256px-rounded.png' style={{ height: '2em' }} alt='Simple Thesis' />
                            </div>
                        </DrawerHeader>
                        <List>
                            {!!menuItems && menuItems.map(item => (
                                <NavItem
                                    key={item.label}
                                    active={isCurrentPage(item.url)}
                                    collapsed={!open}
                                    icon={item.icon}
                                    label={item.label}
                                    onClick={() => navigate(item.url)}
                                />
                            ))}
                        </List>
                    </Box>
                </Drawer>
                {/* <Box
                    sx={{ display: 'flex', flexDirection: 'column', width: '0px', my: 4, justifyContent: 'flex-end' }}
                >
                    <IconButton
                        onClick={() => toggle(!open)}
                        sx={{ border: '1px solid #ccc', borderRadius: '8px', backgroundColor: 'white', height: '6px', width: '6px', transform: 'translateX(-50%)', zIndex: 1200 }}
                    >
                        <ChevronLeftRounded className={`drawer-toggle${open ? '-is-open' : ''}`} />
                    </IconButton>
                </Box> */}
            </Box>
        )
}

export default CollapseDrawer