import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
//Slices
// import appReducer from './slices/app';
import clientReducer from './slices/clients'
import invoiceReducer from './slices/invoices'
import timeLogsReducer from './slices/timeLogs'
import usersReducer from './slices/users'

const reducers = combineReducers({
    clients: clientReducer,
    invoices: invoiceReducer,
    timeLogs: timeLogsReducer,
    users: usersReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;