import React from 'react';
import { TextField } from '@mui/material';

interface InputProps {
  defaultValue?: string | number
  disabled?: boolean
  error?: string
  fullWidth?: boolean
  label: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  placeholder?: string
  style?: object
  type?: 'password' | 'textarea' | string
  value?: string
}

function Input(props: InputProps) {
  const label = props.error ? props.label + ': ' + props.error : props.label;

  return (
    <div style={{ display: 'block', width: props.fullWidth ? '100%' : 150 }}>
      {
        <p className={`input-label ${!!props.error ? 'input-error-label' : ''}`}>
          {label}
        </p>
      }
      <TextField
        {...props as Omit<InputProps, 'error'>}
        error={!!props.error}
        fullWidth={props.fullWidth}
        label=''
        margin='none'
        multiline={props.type === 'textarea'}
        onBlur={props.onBlur}
        onChange={props.onChange}
        placeholder={props.placeholder || ''}
        size='small'
        InputProps={{
          disableUnderline: true,
          style: {
              display: 'flex',
              minHeight: '100%',
              padding: '5px',
              alignItems: props.type === 'textarea' ? 'flex-start' : 'center',
          }
        }}
        style={{
          border: '1px solid var(--theme-dark-grey)',
          borderRadius: '0.5em',
          minHeight: props.type === 'textarea' ? '6em' : '2.5em',
          height: props.type === 'textarea' ? '100%' : '2.5em',
          backgroundColor: 'white'
        }}
        value={props.defaultValue}
        variant='standard'
      />
    </div>
  );
}
export default Input;