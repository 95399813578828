import React, { useMemo, useState } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import { TimeLog, TimeLogStatus } from 'types/index';

const getStatusColor = (status: TimeLogStatus) => {
    switch (status) {
        case TimeLogStatus.Comped:
            return 'var(--theme-red)'
        case TimeLogStatus.FixedBid:
            return 'var(--theme-orange)'
        case TimeLogStatus.Invoiced:
            return 'var(--theme-yellow)'
        case TimeLogStatus.Paid:
            return 'var(--theme-green)'
        case TimeLogStatus.Unbilled:
            return 'var(--theme-secondary)'
        default:
            return 'var(--theme-red)'
    }
}

const BillingStatusChip = styled(Chip)<{ status: TimeLogStatus }>(({ theme, status }) => {
    const color = useMemo(() => { return getStatusColor(status) }, [status])
    return {
        backgroundColor: color,
        color: 'black',
        cursor: 'pointer',
    };
});

const BillingStatus = ({ 
  status, 
  onStatusUpdate, 
  autoSave = true 
}: { 
  status: TimeLogStatus, 
  onStatusUpdate: (status: TimeLogStatus) => void,
  autoSave?: boolean 
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState<TimeLogStatus>(status);

    const handleClick = (event: any) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event?: any) => {
        setAnchorEl(null);
        if (event) {
            event.stopPropagation()
        }
    };

    const handleStatusSelect = (newStatus: TimeLogStatus) => {
        handleClose();
        if (newStatus !== selectedStatus) {
            setSelectedStatus(newStatus);
            if (autoSave) {
                onStatusUpdate(newStatus);
            }
        }
    };

    const statusOptions = ['Unbilled', 'Invoiced', 'Paid', 'Fixed Bid', 'Comped'];

    return (
        <>
            <BillingStatusChip
                label={selectedStatus}
                onClick={handleClick}
                status={selectedStatus}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {statusOptions.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={(e) => { e.stopPropagation(); handleStatusSelect(option as TimeLogStatus) }}
                        selected={option === selectedStatus}
                        sx={{
                            "&.Mui-selected": {
                                backgroundColor: (theme) => theme.palette.action.selected
                            }
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default BillingStatus;