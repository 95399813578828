import { BaseButton, Input } from 'components'
import { Endpoints } from 'Constants'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { User, Notification, UserRoles } from 'types'
import ApiHelper from 'utilities/apiHelper'
import * as Yup from 'yup'

interface FormProps {
    mode: 'invite' | 'edit'
    user: User | null
    onFormSubmitted?: () => void
}

const Schema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    firstname: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
})

const Form = ({ mode, user, onFormSubmitted }: FormProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const onSubmit = async (values: Partial<User>) => {
        if (mode == 'edit') {
            await editUser(values)
        } else {
            await saveUser(values)
        }
        
    }
    const editUser = async (values: Partial<User> | null) => {
        if (!values || !values.iduser) {
            let notification = new Notification('error', 'The selected User is invalid.')
            enqueueSnackbar(notification.get())
            return
        }

        let response: any = await ApiHelper.post(Endpoints.Users, values)

        if (response.success) {
            let notification = new Notification('success', 'The User\'s information has been updated.')
            enqueueSnackbar(notification.get())
            if (onFormSubmitted) {
                onFormSubmitted()
            }
        } else {
            let notification = new Notification('error', 'Error while updating the User.')
            enqueueSnackbar(notification.get())
        }
    }
    const saveUser = async (values: Partial<User> | null) => {
        if (!values || !values.email || !values.firstname || !values.lastname || !values.role) {
            let notification = new Notification('error', 'User is invalid.')
            enqueueSnackbar(notification.get())
            return
        }

        let newUser: Partial<User> = {
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            role: values.role,
        }

        let response: any = await ApiHelper.put(Endpoints.Users, newUser)

        if (response.success) {
            let notification = new Notification('success', `Added ${values.firstname} as a new user to the Citadel!`)
            enqueueSnackbar(notification.get())
            if (onFormSubmitted) {
                onFormSubmitted()
            }
        } else {
            let notification = new Notification('error', 'Error while adding the user.')
            enqueueSnackbar(notification.get())
            console.error(response)
        }
    }

    return (
        <Formik
            initialValues={user || {
                iduser: 0,
                authid: '',
                created: '',
                email: '',
                firstname: '',
                lastname: '',
                role: UserRoles.Contributor,
                updated: '',
            }}
            validationSchema={Schema}
            onSubmit={async (values, FormikHelpers) => {await onSubmit(values)}}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <Input name='firstname' label='First Name' fullWidth defaultValue={values.firstname} error={touched.firstname && errors.firstname ? errors.firstname : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='lastname' label='Last Name' fullWidth defaultValue={values.lastname} error={touched.lastname && errors.lastname ? errors.lastname : ''} onChange={handleChange} onBlur={handleBlur} />
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <Input name='email' label='Email' disabled={mode == 'edit'} fullWidth defaultValue={values.email} error={touched.email && errors.email ? errors.email : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='role' label='Role' defaultValue={values.role} fullWidth disabled />
                        </Stack>
                        
                        <BaseButton text={mode == 'edit' ? 'Update' : 'Invite'} icon='save' disabled={!dirty || !isValid} loading={isSubmitting} onClick={handleSubmit} type='submit' />
                    </Stack>
                </form>
            )}
        </Formik>
    )
}

export default Form