import { BaseButton, ButtonGroup } from 'components/buttons'
import { LineChart } from 'components/charts'
import { ClientForm } from 'components/forms'
import { Checkbox, Dropdown, Input, TextArea } from 'components/inputs'
import { Drawer, Table } from 'components/layout'
import { Endpoints } from '../Constants'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getClients, setClientList } from 'store/slices/clients'
import { getInvoices } from 'store/slices/invoices'
import { Client, Notification, Project } from 'types'
import { ApiHelper, AuthUtility, Helpers } from 'utilities'
import * as Yup from 'yup'


const ProjectSchema = Yup.object().shape({
  active: Yup.boolean().required('Required'),
  billhourly: Yup.boolean().required('Required'),
  budget: Yup.number().required('Required'),
  description: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  rate: Yup.number().required('Required'),
})

function Clients() {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const clientList = useAppSelector(getClients)
  const invoiceList = useAppSelector(getInvoices)
  const [projectList, setProjectList] = useState<Project[]>()
  const [projectDrawerOpen, toggleProjectDrawer] = useState<boolean>(false)
  const [clientDrawerOpen, toggleClientDrawer] = useState<boolean>(false)
  // TODO: Convert selectedClient to an id only
  const [selectedClient, setSelectedClient] = useState<Client | null>()
  const [selectedProject, setSelectedProject] = useState<number | null>()
  const [currentProject, setCurrentProject] = useState<Project | null>(projectList?.find(el => el.idproject == selectedProject) || null)
  const [__formatted_projects, setFormattedProjectList] = useState<Partial<Project>[] | null>(null)
  const clientInvoices = invoiceList.filter(el => el.idclient == selectedClient?.idclient || 0)
  const invoiceTotalsByMonth = useMemo(() => Helpers.GroupInvoicesByMonth(clientInvoices), [clientInvoices.length])

  const loadData = async () => {
    let signedIn = await AuthUtility.IsSignedIn()
    if (!signedIn) {
      let notification = new Notification('error', 'Auth is expired. Please sign in again.')
      enqueueSnackbar(notification.get())
      return
    }

    let clientsResponse: any = await ApiHelper.get(Endpoints.Clients)
    let projectsResponse: any = await ApiHelper.get(Endpoints.Projects)

    if (clientsResponse.success) {
      dispatch(setClientList(clientsResponse.data))
    } else {
      let notification = new Notification('error', 'Error while loading Clients')
      enqueueSnackbar(notification.get())
    }

    if (projectsResponse.success) {
      setProjectList(projectsResponse.data)
    } else {
      let notification = new Notification('error', 'Error while loading Projects')
      enqueueSnackbar(notification.get())
      console.error(projectsResponse)
    }
  }

  const projectClicked = (project: Project) => {
    setSelectedProject(project.idproject)
    setCurrentProject(project)
    toggleProjectDrawer(true)
  }

  const saveProject = async (project: Partial<Project>) => {
    if (!selectedClient?.idclient) {
      let notification = new Notification('error', 'Need to select a client.')
      enqueueSnackbar(notification.get())
      return
    }

    project.created = DateTime.now().toISO()
    project.updated = DateTime.now().toISO()
    project.idclient = selectedClient.idclient

    let response: any = await ApiHelper.put(Endpoints.Projects, project)

    if (response.success) {
      setProjectList([...projectList || [], project as Project])
      let notification = new Notification('success', 'New project added! Keep it up.')
      enqueueSnackbar(notification.get())
      setSelectedProject(null)
      toggleProjectDrawer(false)
    } else {
      let notification = new Notification('error', 'Error while saving the project')
      enqueueSnackbar(notification.get())
    }
  }

  const editProject = async (project: Partial<Project>) => {
    if (!project.idproject) {
      let notification = new Notification('error', 'The current project is invalid.')
      enqueueSnackbar(notification.get())
      return
    }

    project.updated = DateTime.now().toISO()

    let response: any = await ApiHelper.post(Endpoints.Projects, project)

    if (response.success) {
      let notification = new Notification('success', 'Edited the project')
      enqueueSnackbar(notification.get())
      setSelectedProject(null)
      toggleProjectDrawer(false)
      loadData()
    } else {
      let notification = new Notification('error', 'Error while updating the project.')
      enqueueSnackbar(notification.get())
    }
  }

  const onClientChanged = (client: Client) => {
    if (!client) {
      let notification = new Notification('error', 'Invalid Client')
      enqueueSnackbar(notification.get())
      return
    }

    setSelectedClient(client)
  }

  const getClientName = (clientId: number): string => {
    if (!clientList) {
      return ''
    }
    let client = clientList?.find(el => el.idclient === clientId)
    return client?.organization || 'N/A'
  }

  const formatProjectList = (list: Project[]) => {
    const arr = list.map(el => (
      {
        ...el,
        client: getClientName(el.idclient),
        created: Helpers.FormatDateTime(el.created),
        name: el.name,
        rate_budget: el.billhourly ? `$${el.rate} / hr` : `$${el.budget}`,
      }
    ))
    setFormattedProjectList(arr)
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!!projectList) {
      formatProjectList(projectList)
    } else {

    }
  }, [projectList])

  return (
    <div className='page-container' style={{ paddingTop: '2rem' }}>
      {!!selectedClient && (
        <Stack spacing={2}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h1'>{selectedClient.organization}</Typography>
            <ButtonGroup onClose={() => setSelectedClient(null)} />
          </Box>
          <Stack spacing={1} direction='row'>
            <Paper style={{ flexGrow: 1 }}>
              <ClientForm client={selectedClient} onFormSubmitted={() => setSelectedClient(null)} />
            </Paper>
            <Paper style={{ flexGrow: 1 }}>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='h2'>Projects</Typography>
                <ButtonGroup
                  onAdd={() => toggleProjectDrawer(true)}
                  onSearch={() => alert('Search!')}
                />
              </Box>
              {!projectList && (
                <CircularProgress />
              )}
              {!!projectList && (
                <Table
                  columns={[
                    { field: 'name', label: 'Project Name' },
                    { field: 'rate', label: 'Rate' },
                  ]}
                  onRowClicked={(row) => projectClicked(row)}
                  rows={projectList.filter(project => project.idclient == selectedClient.idclient)}
                />
              )}
            </Paper>
          </Stack>
          <Paper>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h2'>Invoices</Typography>
              <ButtonGroup
                onAdd={() => alert('Add!')}
                onSearch={() => alert('Search!')}
              />
            </Box>
            {!invoiceList && (
              <CircularProgress />
            )}
            {!invoiceList || clientInvoices.length === 0 && (
              <Typography variant='h4'>No recent invoices</Typography>
            )}
            {!!invoiceList && clientInvoices.length > 0 && (
              // <InvoiceTable invoices={invoiceList.filter(el => el.idclient == selectedClient.idclient)} onClick={() => { }} />
              // <BarChart labels={Helpers.Months} height={500} data={invoiceTotalsByMonth} />
              <LineChart labels={Helpers.Months} height={500} data={[{ curve: 'linear', data: invoiceTotalsByMonth, color: 'var(--theme-primary)' }]} />
            )}
          </Paper>
        </Stack>
      )}
      {!selectedClient && (
        <Stack spacing={2}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h1'>Clients</Typography>
            <ButtonGroup
              onAdd={() => toggleClientDrawer(true)}
              onSearch={() => alert('Search!')}
            />
          </Box>
          <Paper>
            {!clientList && (
              <CircularProgress />
            )}
            {!!clientList && (
              <Table
                columns={[
                  { field: 'organization', label: 'Business Name' },
                  { field: 'email', label: 'Email' },
                  { field: 'phonenumber', label: 'Phone Number' },
                  { field: 'additionalemails', label: 'Additional Emails' },
                ]}
                onRowClicked={(row) => setSelectedClient(row)}
                rows={clientList}
              />
            )}
          </Paper>
        </Stack>
      )}
      <Drawer heading={selectedClient ? 'Edit Client' : 'Add Client'} open={clientDrawerOpen} toggleDrawer={toggleClientDrawer}>
        <ClientForm client={selectedClient || null} onFormSubmitted={() => toggleClientDrawer(false)} />
      </Drawer>
      <Drawer heading={currentProject ? 'Edit Project' : 'Add Project'} open={projectDrawerOpen} toggleDrawer={toggleProjectDrawer}>
        <Formik
          initialValues={currentProject || {
            idclient: 0,
            active: true,
            billhourly: true,
            budget: 0,
            description: '',
            name: '',
            rate: 0,
          }}
          validationSchema={ProjectSchema}
          onSubmit={values => {
            if (currentProject) {
              editProject(values)
            } else {
              saveProject(values)
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {!!currentProject && (
                  <Typography variant='h2'>{clientList?.find(el => el.idclient === currentProject?.idclient)?.organization}</Typography>
                )}
                {!currentProject && (
                  <Dropdown
                    label='Client'
                    displayKey='organization'
                    idKey='idclient'
                    fullWidth
                    items={clientList}
                    onSelect={onClientChanged} />
                )}
                <Input
                  fullWidth={true}
                  name='name'
                  label='Project Name'
                  defaultValue={values.name}
                  error={touched.name && errors.name ? errors.name : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox defaultValue={values.billhourly} error={touched.billhourly && errors.billhourly ? errors.billhourly : ''} label='Bill Hourly' name='billhourly' onBlur={handleBlur} onChange={handleChange} />
                {values.billhourly
                  ? <Input name='rate' label='Hourly Rate' defaultValue={values.rate} error={touched.rate && errors.rate ? errors.rate : ''} fullWidth={true} onChange={handleChange} onBlur={handleBlur} />
                  : <Input name='budget' label='Budget' defaultValue={values.budget} error={touched.budget && errors.budget ? errors.budget : ''} fullWidth={true} onChange={handleChange} onBlur={handleBlur} type='number' />
                }
                <TextArea name='description' label='Description' defaultValue={values.description} error={touched.description && errors.description ? errors.description : ''} onChange={handleChange} onBlur={handleBlur} />
                <BaseButton text={!!selectedProject ? 'Update Project' : 'Add Project'} icon='save' loading={isSubmitting} onClick={handleSubmit} type='submit' />
              </Stack>
            </form>
          )}
        </Formik>
      </Drawer>
    </div>
  )
}

export default Clients