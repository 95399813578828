import { Box, CircularProgress, Divider, IconButton, Paper, Stack } from '@mui/material'
import { AddRounded, CloseRounded, DeleteRounded, EditRounded, SearchRounded } from '@mui/icons-material'

interface ButtonGroupProps {
    onAdd?: () => void
    onClose?: () => void
    onDelete?: () => void
    onEdit?: () => void
    onSearch?: () => void
}

const ButtonGroup = ({ onAdd, onClose, onDelete, onEdit, onSearch }: ButtonGroupProps) => {
    return (
        <Box>
            <Paper elevation={4} sx={{ p: 0 }}>
                <Stack direction='row' divider={<Divider orientation='vertical' flexItem />}>
                    {!!onAdd && (
                        <IconButton sx={{ borderRadius: 0 }} onClick={onAdd}>
                            {false
                                ? <CircularProgress size='1em' />
                                : <AddRounded fontSize='small' />
                            }
                        </IconButton>
                    )}
                    {!!onSearch && (
                        <IconButton sx={{ borderRadius: 0 }} onClick={onSearch}>
                            {false
                                ? <CircularProgress size='1em' />
                                : <SearchRounded fontSize='small' />
                            }
                        </IconButton>
                    )}
                    {!!onEdit && (
                        <IconButton sx={{ borderRadius: 0 }} onClick={onEdit}>
                            {false
                                ? <CircularProgress size='1em' />
                                : <EditRounded fontSize='small' />
                            }
                        </IconButton>
                    )}
                    {!!onDelete && (
                        <IconButton sx={{ borderRadius: 0 }} onClick={onDelete}>
                            {false
                                ? <CircularProgress size='1em' />
                                : <DeleteRounded fontSize='small' />
                            }
                        </IconButton>
                    )}
                    {!!onClose && (
                        <IconButton sx={{ borderRadius: 0 }} disabled={false} onClick={onClose}>
                            {false
                                ? <CircularProgress size='1em' />
                                : <CloseRounded fontSize='small' />
                            }
                        </IconButton>
                    )}
                </Stack>
            </Paper>
        </Box>
    )
}

export default ButtonGroup