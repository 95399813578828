import React from 'react'
import { Drawer as MuiDrawer, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

interface DrawerProps {
  children: React.ReactNode
  heading: string
  open: boolean
  toggleDrawer(open: boolean): void
  width?: number | string
}

function Drawer({ children, heading, open, toggleDrawer, width }: DrawerProps) {
  const close = () => {
    toggleDrawer(false)
  }

  return (
    <MuiDrawer
      anchor='right'
      open={open}
      onClose={close}
      PaperProps={{
        style: {
          width: width || '500px',
          border: '2px solid var(--theme-primary)',
          borderRightWidth: '0px',
          borderRadius: '20px 0px 0px 20px',
          padding: '2em'
        }
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='drawer-row'>
          <Typography variant='h2'>{heading}</Typography>
          <CloseRoundedIcon htmlColor='#000000' onClick={close} />
        </div>
        {children}
      </div>
    </MuiDrawer>
  )
}

export default Drawer