import { 
    Table as MuiTable, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Typography, 
} from '@mui/material'
import { FC } from 'react'

const Table: FC<{
    columns: { field: string, label: string }[],
    onRowClicked: (item: any) => void
    rows: Array<any>
}> = ({
    columns, onRowClicked, rows
}) => {
        return (
            <TableContainer>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {columns.map(el => (
                                <TableCell key={el.field}>
                                    <Typography variant='body2'>{el.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id} onClick={() => onRowClicked(row)}>
                                {columns.map(col => (
                                    <TableCell key={row[col.field]}>
                                        <Typography>{row[col.field]}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        )
    }

export default Table