import { BaseButton, Input, TextArea } from 'components'
import { Endpoints } from 'Constants'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { Box, Container, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Client, Notification } from 'types'
import ApiHelper from 'utilities/apiHelper'
import * as Yup from 'yup'

interface FormProps {
    client: Client | null
    onFormSubmitted?: () => void
}

const Schema = Yup.object().shape({
    additionalemails: Yup.string().nullable(),
    defaultpayperiod: Yup.number().required('Required').min(0, 'A negative pay period would be ridiculous'),
    email: Yup.string().email('Invalid').required('Required'),
    firstname: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    phonenumber: Yup.string().required('Required'),
})

const Form = ({ client, onFormSubmitted }: FormProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const onSubmit = async (values: Client) => {
        if (client?.idclient) {
            await editClient(values)
        } else {
            await saveClient(values)
        }
        
        if (onFormSubmitted) {
            onFormSubmitted()
        }
    }
    const editClient = async (values: Client | null) => {
        if (!values || !values.idclient) {
            let notification = new Notification('error', 'The selected Client is invalid.')
            enqueueSnackbar(notification.get())
            return
        }

        // TODO: Move this to the server
        values.updated = DateTime.now().toISO()

        // TODO: Add a type for API responses
        let response: any = await ApiHelper.post(Endpoints.Clients, values)

        if (response.success) {
            let notification = new Notification('success', 'The Client\'s information has been updated.')
            enqueueSnackbar(notification.get())
        } else {
            let notification = new Notification('error', 'Error while updating the Client.')
            enqueueSnackbar(notification.get())
        }
        
        if (onFormSubmitted) {
            onFormSubmitted()
        }
    }
    const saveClient = async (values: Client | null) => {
        if (!values || !values.organization || !values.email || !values.firstname || !values.lastname) {
            let notification = new Notification('error', 'Client is invalid.')
            enqueueSnackbar(notification.get())
            return
        }

        let newClient: Partial<Client> = {
            additionalemails: values.additionalemails,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            defaultpayperiod: values.defaultpayperiod,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            organization: values.organization,
            phonenumber: values.phonenumber,
            state: values.state,
            zipcode: values.zipcode,

            // TODO: Move these to the server
            // idclient: 0,
            created: DateTime.now().toISO(),
            updated: DateTime.now().toISO(),
        }

        let response: any = await ApiHelper.put(Endpoints.Clients, newClient)

        if (response.success) {
            let notification = new Notification('love', `Added ${values.organization} as a new client! Pat yourself on the back for this one!`)
            enqueueSnackbar(notification.get())
        } else {
            let notification = new Notification('error', 'Error while adding the client.')
            enqueueSnackbar(notification.get())
            console.error(response)
        }
    }

    return (
        <Formik
            initialValues={client || {
                idclient: 0,
                additionalemails: '',
                address1: '',
                address2: '',
                city: '',
                created: '',
                defaultpayperiod: 0,
                email: '',
                firstname: '',
                lastname: '',
                organization: '',
                phonenumber: '',
                state: '',
                updated: '',
                zipcode: '',
            }}
            
            validationSchema={Schema}
            onSubmit={async (values, FormikHelpers) => {await onSubmit(values); FormikHelpers.resetForm()}}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Input name='organization' label='Business Name' fullWidth defaultValue={values.organization} error={touched.organization && errors.organization ? errors.organization : ''} onChange={handleChange} onBlur={handleBlur} />
                        <Stack direction='row' spacing={2}>
                            <Input name='firstname' label='First Name' fullWidth defaultValue={values.firstname} error={touched.firstname && errors.firstname ? errors.firstname : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='lastname' label='Last Name' fullWidth defaultValue={values.lastname} error={touched.lastname && errors.lastname ? errors.lastname : ''} onChange={handleChange} onBlur={handleBlur} />
                        </Stack>
                        <Stack direction='row' spacing={2} width='100%'>
                            <Input name='phonenumber' label='Phone Number' fullWidth defaultValue={values.phonenumber} error={touched.phonenumber && errors.phonenumber ? errors.phonenumber : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='defaultpayperiod' label='Default Pay Period' fullWidth defaultValue={values.defaultpayperiod.toString()} error={touched.defaultpayperiod && errors.defaultpayperiod ? errors.defaultpayperiod : ''} onChange={handleChange} onBlur={handleBlur} type='number' />
                        </Stack>
                        <Input name='email' label='Email' fullWidth defaultValue={values.email} error={touched.email && errors.email ? errors.email : ''} onChange={handleChange} onBlur={handleBlur} />
                        <Input name='additionalemails' label='Additional Emails' type='textarea' fullWidth defaultValue={values.additionalemails} error={touched.additionalemails && errors.additionalemails ? errors.additionalemails : ''} onChange={handleChange} onBlur={handleBlur} />
                        
                        <Input name='address1' label='Address Line 1' fullWidth defaultValue={values.address1} error={touched.address1 && errors.address1 ? errors.address1 : ''} onChange={handleChange} onBlur={handleBlur} />
                        <Input name='address2' label='Address Line 2' fullWidth defaultValue={values.address2} error={touched.address2 && errors.address2 ? errors.address2 : ''} onChange={handleChange} onBlur={handleBlur} />
                        <Stack direction='row' spacing={1}>
                            <Input name='city' label='City' fullWidth defaultValue={values.city} error={touched.city && errors.city ? errors.city : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='state' label='State' fullWidth defaultValue={values.state} error={touched.state && errors.state ? errors.state : ''} onChange={handleChange} onBlur={handleBlur} />
                            <Input name='zipcode' label='Zip Code' fullWidth defaultValue={values.zipcode} error={touched.zipcode && errors.zipcode ? errors.zipcode : ''} onChange={handleChange} onBlur={handleBlur} />
                        </Stack>

                        <BaseButton text={!!client ? 'Update' : 'Create'} icon='save' disabled={!dirty || !isValid} loading={isSubmitting} onClick={handleSubmit} type='submit' />
                    </Stack>
                </form>
            )}
        </Formik>
    )
}

export default Form