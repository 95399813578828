import { BaseButton, ButtonGroup } from 'components/buttons'
import { BaseDialog } from 'components/dialogs'
import { UserForm } from 'components/forms'
import { Dropdown } from 'components/inputs'
import { Endpoints } from '../Constants'
import { Box, Paper, Stack, Typography, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getCurrentUser, getUsers, setUsers, updateUser } from 'store/slices'
import { getClients } from 'store/slices/clients'
import { Notification, User, Project, DropDownItem, UserRoles, Client } from 'types'
import { ApiHelper } from 'utilities'

function Users() {
  const currentUser = useAppSelector(getCurrentUser)
  const clientList = useAppSelector(getClients)
  const userList = useAppSelector(getUsers)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedUser, setSelectedUser] = useState<User | null>()
  const [isInviteUserOpen, setIsInviteUserOpen] = useState<boolean>(false)
  const [isAssignProjectOpen, setIsAssignProjectOpen] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [projectList, setProjectList] = useState<Project[]>()
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([])

  const loadData = async () => {
    let response: any = await ApiHelper.get(Endpoints.Users)
    if (response.success) {
      dispatch(setUsers(response.data))
    } else {
      let notification = new Notification('error', 'Failed to load users')
      enqueueSnackbar(notification.get())
    }

    let projectsResponse: any = await ApiHelper.get(Endpoints.Projects)
    if (projectsResponse.success) {
      setProjectList(projectsResponse.data)
    } else {
      let notification = new Notification('error', 'Failed to load projects')
      enqueueSnackbar(notification.get())
    }
  }

  const userClicked = (user: User) => {
    if (!user) {
      return
    }
    setSelectedUser(user);
  }

  const onUserInvited = () => {
    setIsInviteUserOpen(false)
    loadData()
  }

  const toggleProjectAssignment = async (projectId: number) => {
    if (!selectedUser) return;
    
    let response: any = await ApiHelper.post(Endpoints.UserProjectsRemove, {
      iduser: selectedUser.iduser,
      idproject: projectId
    });

    if (response.success) {
      // Update the user's projects in place
      const updatedUser = {
        ...selectedUser,
        projects: selectedUser.projects.filter(p => p.idproject !== projectId)
      };
      dispatch(updateUser(updatedUser));
      setSelectedUser(updatedUser);
      
      let notification = new Notification('success', 'Project removed successfully');
      enqueueSnackbar(notification.get());
    } else {
      let notification = new Notification('error', 'Failed to remove project');
      enqueueSnackbar(notification.get());
    }
  };

  const handleAssignProject = async () => {
    if (!selectedProject || !selectedClient || !selectedUser) return;
    
    let response: any = await ApiHelper.post(Endpoints.UserProjectsAssign, {
      iduser: selectedUser.iduser,
      idproject: selectedProject.idproject
    });

    if (response.success) {
      // Find the project details from projectList
      const projectToAdd = projectList?.find(p => p.idproject === selectedProject.idproject);
      if (projectToAdd) {
        // Update the user's projects in place
        const updatedUser = {
          ...selectedUser,
          projects: [...selectedUser.projects, projectToAdd]
        };
        dispatch(updateUser(updatedUser));
        setSelectedUser(updatedUser);
      }
      
      setIsAssignProjectOpen(false);
      setSelectedClient(null);
      setSelectedProject(null);
      
      let notification = new Notification('success', 'Project assigned successfully');
      enqueueSnackbar(notification.get());
    } else {
      let notification = new Notification('error', 'Failed to assign project');
      enqueueSnackbar(notification.get());
    }
  };

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (selectedClient && projectList) {
      setFilteredProjects(projectList.filter(p => p.idclient === selectedClient.idclient))
    } else {
      setFilteredProjects([])
    }
  }, [selectedClient, projectList])

  const getClientName = (clientId: number): string => {
    if (!clientList) {
      return ''
    }
    let client = clientList?.find(el => el.idclient === clientId)
    return client?.organization || 'N/A'
  }

  return (
    <div className='page-container' style={{ paddingTop: '2rem' }}>
      {!!selectedUser && (
        <Stack spacing={2}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h1'>{selectedUser.firstname + ' ' + selectedUser.lastname}</Typography>
            <ButtonGroup onClose={() => setSelectedUser(null)} />
          </Box>
          <Paper style={{ flexGrow: 1 }}>
            <UserForm mode='edit' user={selectedUser} onFormSubmitted={() => {loadData(); setSelectedUser(null)}} />
          </Paper>
          {selectedUser?.role !== UserRoles.Admin && (
            <Paper>
              <Box p={2}>
                <Box display='flex' justifyContent='space-between' alignItems='center' style={{ marginBottom: '1rem' }}>
                  <Typography variant='h2'>Assigned Projects</Typography>
                  <ButtonGroup onAdd={() => setIsAssignProjectOpen(true)} />
                </Box>
                {!selectedUser.projects || selectedUser.projects.length === 0 ? (
                  <Typography color="text.secondary">This user is not currently assigned to any projects.</Typography>
                ) : (
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                        <td style={{ flex: 1 }}>Client</td>
                        <td style={{ flex: 1 }}>Project</td>
                        <td style={{ flex: 0.5 }}>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUser.projects.map((project) => (
                        <tr
                          key={project.idproject}
                          style={{ borderBottom: '0px dashed #555', display: 'flex' }}
                        >
                          <td style={{ flex: 1 }}>{getClientName(project.idclient)}</td>
                          <td style={{ flex: 1 }}>{project.name}</td>
                          <td style={{ flex: 0.5 }}>
                            <BaseButton 
                              text="Remove"
                              icon="delete"
                              variant="delete"
                              onClick={() => toggleProjectAssignment(project.idproject)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Box>
            </Paper>
          )}
          <BaseDialog
            isOpen={isAssignProjectOpen}
            cancellable={true}
            onClose={() => setIsAssignProjectOpen(false)}
            heading='Add user to project'
            width={300}
          >
            <Stack spacing={2}>
              <Dropdown
                name='client'
                label="Client"
                idKey="idclient"
                fullWidth={true} 
                displayKey="organization"
                items={clientList}
                onSelect={(item) => setSelectedClient(item)}
              />
              <Dropdown
                label="Project"
                items={filteredProjects}
                displayKey="name"
                idKey="idproject"
                fullWidth={true} 
                disabled={!selectedClient}
                defaultValue={selectedProject || undefined}
                onSelect={(item) => setSelectedProject(item)}
              />
              <BaseButton
                text="Assign Project"
                icon="add"
                onClick={handleAssignProject}
                fullWidth={true} 
                disabled={!selectedProject}
              />
            </Stack>
          </BaseDialog>
        </Stack>
      )}
      {!selectedUser && (
        <Stack spacing={2}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h1'>Users</Typography>
            <ButtonGroup
              onAdd={() => setIsInviteUserOpen(true)}
              onSearch={() => alert('Search!')}
            />
          </Box>
          <Paper>
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                  <td style={{ flex: 1 }}>First Name</td>
                  <td style={{ flex: 1 }}>Last Name</td>
                  <td style={{ flex: 1 }}>Email</td>
                </tr>
              </thead>
              <tbody>
                {userList && userList.map((el) => (
                  <tr
                    key={el.email}
                    style={{ borderBottom: '0px dashed #555', display: 'flex' }}
                    onClick={() => setSelectedUser(el)}
                  >
                    <td style={{ flex: 1 }}>{el.firstname}</td>
                    <td style={{ flex: 1 }}>{el.lastname}</td>
                    <td style={{ flex: 1 }}>{el.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Box p={2} display="flex" justifyContent="center">
              <BaseButton
                text="Reload Users"
                icon="search"
                onClick={loadData}
              />
            </Box>
          </Paper>
        </Stack>
      )}
      <BaseDialog
          isOpen={isInviteUserOpen}
          cancellable={true}
          onClose={() => setIsInviteUserOpen(false)}
          heading='Invite User'
      >
          <div style={{ maxHeight: '550px' }}>
              <UserForm 
                user={null}
                mode='invite'
                onFormSubmitted={() => onUserInvited()}
              />
          </div>
      </BaseDialog>
    </div>
  )
}

export default Users