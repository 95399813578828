import { Endpoints } from '../Constants'
import { BaseButton, Drawer, Dropdown, Input } from 'components'
import { Formik } from 'formik'
import { DateTime, Duration } from 'luxon'
import { Box, Divider, Grid, IconButton, MenuItem, Paper, Select, SelectChangeEvent, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getClients, setClientList } from 'store/slices/clients'
import { getCurrentUser } from 'store/slices/users'
import { Client, DropDownItem, Project, Notification, SummaryItem, TimeLog, TimeLogStatus, UserRoles } from 'types'
import { ApiHelper, Helpers } from 'utilities'
import *  as Yup from 'yup'
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import BillingStatus from 'components/chips/TimeLogStatus'

const FormSchema = Yup.object().shape({
  client: Yup.string().required('Required'),
  date: Yup.string().required('Required'),
  hours: Yup.number().min(0, 'No negatives').required('Required'),
  notes: Yup.string().required('Required'),
  project: Yup.string().required('Required'),
})

function WorkJournal() {
  const dispatch = useAppDispatch()
  const clientList = useAppSelector(getClients)
  const currentUser = useAppSelector(getCurrentUser)
  const { enqueueSnackbar } = useSnackbar()
  const [drawerOpen, toggleDrawer] = useState<boolean>(false)
  const [timeLogs, setTimeLogsList] = useState<TimeLog[]>([])
  const [filteredTimeLogs, setFilteredTimeLogs] = useState<TimeLog[]>([])
  const [client, setClient] = useState<Client | null>()
  const [project, setProject] = useState<Project | null>()
  const [projectList, setProjectList] = useState<Project[]>()
  const [filteredProjects, setFilteredProjects] = useState<Project[]>()
  const [selectedTimeLog, setSelectedTimeLog] = useState<TimeLog | null>()
  const [dateCardFilter, setDateCardFilter] = useState<string>(DateTime.now().toISODate())
  const dateSummaryLogs = useMemo(() => timeLogs.filter(el => el.date === dateCardFilter), [timeLogs, dateCardFilter])
  const dateSummaryHours = useMemo(() => dateSummaryLogs.reduce((acc, current) => acc + current.duration, 0) / 3600, [dateCardFilter, dateSummaryLogs])
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [timeLogToDelete, setTimeLogToDelete] = useState<TimeLog | null>(null)

  const clientChanged = (client: Client) => {
    if (!client) {
      let notification = new Notification('error', 'Invalid Client')
      enqueueSnackbar(notification.get())
      return
    }

    let list = projectList?.filter(el => el.idclient === client?.idclient)

    setClient(client)
    setFilteredProjects(list)
  }

  const projectChanged = (project: Project) => {
    if (!project) {
      let notification = new Notification('error', 'Invalid Project')
      enqueueSnackbar(notification.get())
      return
    }

    setProject(project)
  }

  const addTimeLog = async (values: any, handleReset: () => void) => {
    let timeLog: Partial<TimeLog> = {
      idtimelog: 0,
      idclient: values.client,
      idproject: values.project,
      iduser: 1, //TODO: Use profile info
      billingstatus: TimeLogStatus.Unbilled,
      created: DateTime.now().toISO(),
      date: values.date,
      duration: values.hours * 3600,
      notes: values.notes,
      updated: DateTime.now().toISO(),
    }

    let response: any = await ApiHelper.put(Endpoints.TimeLogs, timeLog)

    if (!response.success) {
      let notification = new Notification('error', 'Error saving time log')
      enqueueSnackbar(notification.get())
      return
    }

    loadData()
    let notification = new Notification('success', 'Time Log saved')
    enqueueSnackbar(notification.get())
    handleReset()
  }

  const editTimeLog = async (values: any) => {
    let timeLog: Partial<TimeLog> = {
      ...selectedTimeLog,
      date: values.date,
      duration: values.hours * 3600,
      notes: values.notes,
      updated: DateTime.now().toISO(),
    }

    let response: any = await ApiHelper.post(Endpoints.TimeLogs, timeLog)

    if (!response.success) {
      loadData()
      let notification = new Notification('error', 'Error editing time log.')
      enqueueSnackbar(notification.get())
      return
    }

    let notification = new Notification('success', 'Edited the time log.')
    enqueueSnackbar(notification.get())
    unselectTimeLog()
    loadData()
  }
  const editTimeLogBillingStatus = async (timeLog: TimeLog, newStatus: TimeLogStatus) => {
    timeLog = {
      ...timeLog,
      billingstatus: newStatus,
      updated: DateTime.now().toISO(),
    }

    let response: any = await ApiHelper.post(Endpoints.TimeLogs, timeLog)

    if (!response.success) {
      loadData()
      let notification = new Notification('error', 'Error editing time log.')
      enqueueSnackbar(notification.get())
      return
    }

    let notification = new Notification('success', 'Edited the time log.')
    enqueueSnackbar(notification.get())
    unselectTimeLog()
    loadData()
  }

  const confirmDelete = (timeLog: TimeLog) => {
    setTimeLogToDelete(timeLog)
    setDeleteConfirmOpen(true)
  }

  const deleteTimeLog = async () => {
    if (!timeLogToDelete) return

    const params = `?idtimelog=${timeLogToDelete.idtimelog}`
    const response: any = await ApiHelper.delete(Endpoints.TimeLogs + params)

    if (!response.success) {
      let notification = new Notification('error', 'Error deleting time log.')
      enqueueSnackbar(notification.get())
      return
    }

    let notification = new Notification('success', 'Deleted the time log.')
    enqueueSnackbar(notification.get())
    setDeleteConfirmOpen(false)
    setTimeLogToDelete(null)
    unselectTimeLog()
    loadData()
  }

  const timeLogClicked = (timeLog: TimeLog) => {
    setSelectedTimeLog(timeLog)
    toggleDrawer(true)
  }

  const unselectTimeLog = () => {
    setSelectedTimeLog(null)
    toggleDrawer(false)
  }

  const loadData = async () => {
    if (!clientList || clientList.length < 1) {
      let clientsResponse: any = await ApiHelper.get(Endpoints.Clients)
      if (clientsResponse.success) {
        console.error(clientsResponse)
        dispatch(setClientList(clientsResponse.data))
      } else {
        let notification = new Notification('error', 'Error loading clients')
        enqueueSnackbar(notification.get())
      }
    }

    let projectsResponse: any = await ApiHelper.get(Endpoints.Projects)
    if (!projectList || projectList.length < 1) {
      if (projectsResponse.success) {
        setProjectList(projectsResponse.data)
        setFilteredProjects(projectsResponse.data)
      } else {
        let notification = new Notification('error', 'Error loading projects')
        enqueueSnackbar(notification.get())
      }
    }

    let now = DateTime.now()
    let start = now.set({ month: now.month - 3 }).startOf('month').toISODate()
    let end = DateTime.local().endOf('month').toISODate()
    let query = `?start=${start}&end=${end}`

    let timeLogsResponse: any = await ApiHelper.get(Endpoints.TimeLogs + query)
    if (timeLogsResponse.success) {
      setTimeLogsList(timeLogsResponse.data)
      setFilteredTimeLogs(timeLogsResponse.data)
      return
    } else if (timeLogsResponse.status === 404) {
      let notification = new Notification('warn', 'No time logs for the current month.')
      enqueueSnackbar(notification.get())
    } else {
      let notification = new Notification('error', 'Error loading time logs')
      enqueueSnackbar(notification.get())
    }

    setTimeLogsList([])
    setFilteredTimeLogs([])
  }

  const searchTimeLogs = async () => {
    let notification = new Notification('bug', 'This is not done yet.')
    enqueueSnackbar(notification.get())
  }

  const clientFilterChanged = (client: Client) => {
    setFilteredTimeLogs(timeLogs.filter(el => el.idclient === client.idclient))

    let list = projectList?.filter(el => el.idclient === client.idclient)

    setFilteredProjects(list)
  }

  const projectFilterChanged = (project: Project) => {
    setFilteredTimeLogs(timeLogs.filter(el => el.idproject === project.idproject))
  }

  const updateCardFilter = (days: number) => {
    let dt = DateTime.fromISO(dateCardFilter)
    let duration = Duration.fromObject({ days })
    let newDate = dt.plus(duration)
    setDateCardFilter(newDate.toISODate())
  }

  useEffect(() => {
    setTimeout(() => {
      loadData()
    }, 20) //Delay load while AWS sets tokens in storage
  }, [])

  return (
    <div className='page-container' style={{ paddingTop: '2rem' }}>
      <Stack spacing={2}>
        <Typography variant='h1'>{Helpers.CurrentMonth()}</Typography>
        <Stack direction='row' spacing={2}>
          <Paper style={{ flex: 1 }}>
            <Formik
              initialValues={{
                client: client?.idclient,
                date: dateCardFilter,
                hours: 0,
                notes: '',
                project: project?.idproject,
              }}
              enableReinitialize
              validateOnMount
              validationSchema={FormSchema}
              validate={values => {
                if (client) {
                  values.client = client.idclient
                }
                if (project) {
                  values.project = project.idproject
                }
                // if (!client || !client.idclient) {
                //   return { client: 'Required' }
                // }
                // if (!project || !project.idproject) {
                //   return { project: 'Required' }
                // }
              }}
              onSubmit={(values, props) => {
                addTimeLog(values, props.resetForm)
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                  <Input name='date' label='Date' defaultValue={dateCardFilter} fullWidth={true} error={touched.date && errors.date ? errors.date : ''} onBlur={handleBlur} onChange={handleChange} type='date' />
                  <Dropdown name='client' label='Client' idKey={'idclient'} fullWidth={true} displayKey='organization' error={touched.client && errors.client ? errors.client : ''} items={clientList} onSelect={clientChanged} />
                  <Dropdown name='project' label='Project' idKey={'idproject'} fullWidth={true} displayKey='name' error={touched.project && errors.project ? errors.project : ''} items={filteredProjects} onSelect={projectChanged} />
                  <Input name='hours' label='Hours' defaultValue={values.hours} fullWidth={true} error={touched.hours && errors.hours ? errors.hours : ''} onBlur={handleBlur} onChange={handleChange} type='number' />
                  <Input name='notes' label='Notes' fullWidth={true} defaultValue={values.notes} error={touched.notes && errors.notes ? errors.notes : ''} onBlur={handleBlur} onChange={handleChange} type='textarea' />
                  {/* <TextArea name='notes' label='Notes' fullWidth={true} defaultValue={values.notes} error={touched.notes && errors.notes ? errors.notes : ''} onBlur={handleBlur} onChange={handleChange} /> */}
                  <BaseButton text='Add Time Log' fullWidth={true} icon='save' loading={isSubmitting} onClick={handleSubmit} type='submit' style={{ marginTop: '1em' }} />
                </form>
              )}
            </Formik>
          </Paper>
          <Paper style={{ flex: 1 }}>
            <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between'>
              <Box>
                <Box display='flex' justifyContent='space-between'>
                  <IconButton onClick={() => updateCardFilter(-1)}>
                    <ArrowBackIosRounded style={{ height: '1rem', width: '1rem' }} />
                  </IconButton>
                  <Typography variant='h2'>{DateTime.fromISO(dateCardFilter).toFormat('EEEE, MMMM d')}</Typography>
                  <IconButton onClick={() => updateCardFilter(1)}>
                    <ArrowForwardIosRounded style={{ height: '1rem', width: '1rem' }} />
                  </IconButton>
                </Box>
                <table style={{ height: '100%', width: '100%', marginTop: '1em' }}>
                  <thead>
                    <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                      <td style={{ flex: 1 }}>Client</td>
                      <td style={{ flex: 1 }}>Project</td>
                      <td style={{ flex: 1 }}>Hours</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!!dateSummaryLogs && dateSummaryLogs.length > 0 && dateSummaryLogs.map(el => (
                      <tr key={el.idtimelog} style={{ borderBottom: '0px dashed #555', display: 'flex' }} onClick={() => timeLogClicked(el)}>
                        <td style={{ flex: 1 }}>{el.client.organization}</td>
                        <td style={{ flex: 1 }}>{el.project.name}</td>
                        <td style={{ flex: 1 }}>{(el.duration / 3600).toFixed(2)}</td>
                      </tr>
                    ))}
                    {!dateSummaryLogs || dateSummaryLogs.length < 1 && (
                      <tr style={{ display: 'flex', pointerEvents: 'none' }}>
                        <td style={{ flex: 1 }}><i>No time logs for the selected date.</i></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Box>
              <Box>
                <Typography variant='body2'>Total: {dateSummaryHours} {dateSummaryHours === 1 ? 'hour' : 'hours'}</Typography>
              </Box>
            </Box>
          </Paper>
        </Stack>
        <Paper>
          <Typography variant='h2'>Time Logs</Typography>
          <div className='row' style={{ justifyContent: 'flex-end' }}>
            {/* <Input name='startDate' label='Start' type='date' /> */}
            {/* <Input name='endDate' label='End' type='date' /> */}
            <Dropdown name='client' label='Client' idKey={'idclient'} displayKey='organization' items={clientList} onSelect={clientFilterChanged} style={{ marginRight: '1em' }} />
            <Dropdown name='project' label='Project' idKey={'idproject'} displayKey='name' items={filteredProjects} onSelect={projectFilterChanged} />
            <BaseButton text='Search' icon='search' />
          </div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #555', display: 'flex' }}>
                <td style={{ flex: 1 }}>Date</td>
                <td style={{ flex: 1 }}>Client</td>
                <td style={{ flex: 1 }}>Project</td>
                <td style={{ flex: 1 }}>Hours</td>
                <td style={{ flex: 3 }}>Notes</td>
              </tr>
            </thead>
            <tbody>
              {filteredTimeLogs && filteredTimeLogs.map(el => (
                <tr key={el.idtimelog} style={{ borderBottom: '0px dashed #555', display: 'flex' }} onClick={() => timeLogClicked(el)}>
                  <td style={{ flex: 1 }}>{el.date}</td>
                  <td style={{ flex: 1 }}>{el.client.organization}</td>
                  <td style={{ flex: 1 }}>{el.project.name}</td>
                  <td style={{ flex: 1 }}>{(el.duration / 3600).toFixed(2)}</td>
                  <td style={{ flex: 3 }}>{el.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      </Stack>
      <Drawer heading='Edit Time Log' open={drawerOpen} toggleDrawer={unselectTimeLog}>
        <Formik
          initialValues={{
            date: selectedTimeLog?.date,
            hours: selectedTimeLog ? selectedTimeLog?.duration / 3600 : 0,
            notes: selectedTimeLog?.notes,
          }}
          validationSchema={Yup.object().shape({
            date: Yup.string().required('Required'),
            hours: Yup.number().min(0, 'No negatives').required('Required'),
            notes: Yup.string().required('Required'),
          })}
          onSubmit={values => {
            editTimeLog(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Stack spacing={1}>
                <p>{selectedTimeLog?.client.organization}</p>
                <p>{selectedTimeLog?.project.name}</p>
                {currentUser?.role === UserRoles.Admin && (
                  <Box>
                    <Typography variant="body2" style={{ marginBottom: '0.5em' }}>Billing Status</Typography>
                    <BillingStatus 
                      status={selectedTimeLog?.billingstatus || TimeLogStatus.Unbilled} 
                      onStatusUpdate={(status) => editTimeLogBillingStatus(selectedTimeLog!, status)} 
                    />
                  </Box>
                )}
                <Stack direction='row' spacing={1} justifyContent='space-between'>
                  <Input name='date' label='Date' fullWidth defaultValue={values.date} error={touched.date && errors.date ? errors.date : ''} onBlur={handleBlur} onChange={handleChange} type='date' />
                  <Input name='hours' label='Hours' fullWidth defaultValue={values.hours} error={touched.hours && errors.hours ? errors.hours : ''} onBlur={handleBlur} onChange={handleChange} type='number' />
                </Stack>
                <Input name='notes' label='Notes' type='textarea' fullWidth defaultValue={values.notes} error={touched.notes && errors.notes ? errors.notes : ''} onBlur={handleBlur} onChange={handleChange} />
                <Stack direction='row' spacing={1} justifyContent='space-between'>
                  <BaseButton text='Save Edits' loading={isSubmitting} fullWidth icon='edit' onClick={handleSubmit} type='submit' />
                  <BaseButton
                    text='Delete'
                    disabled={isSubmitting}
                    icon='delete'
                    fullWidth
                    onClick={() => confirmDelete(selectedTimeLog!)}
                    variant='delete'
                  />
                </Stack>
                <Box>
                  <p style={{ color: '#bbb' }}>Created: {DateTime.fromISO(selectedTimeLog?.created || '').toLocaleString(DateTime.DATETIME_FULL)}</p>
                  {selectedTimeLog?.created != selectedTimeLog?.updated && (
                    <p style={{ color: '#bbb' }}>Updated: {DateTime.fromISO(selectedTimeLog?.updated || '').toLocaleString(DateTime.DATETIME_FULL)}</p>
                  )}
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Drawer>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <Box style={{ padding: '1em' }}>
          <Stack spacing={4}>
            <h3 style={{}}>Confirm Delete</h3>
            <p style={{ margin: 0 }} >Are you sure you want to delete this time log? This action cannot be undone.</p>
            <DialogActions style={{ margin: 0 }}>
              <BaseButton
                text="Cancel"
                onClick={() => setDeleteConfirmOpen(false)}
                variant="secondary"
              />
              <BaseButton
                text="Delete"
                onClick={deleteTimeLog}
                variant="delete"
              />
            </DialogActions>
          </Stack>
        </Box>
      </Dialog>
    </div >
  )
}

export default WorkJournal

interface ProjectChipProps {
  client: Client
  project: Project
  onClick: () => void
}
const ProjectChip = ({ client, project, onClick }: ProjectChipProps) => {
  const label = useMemo(() => {
    let p = Helpers.getProjectAbbr(project.name)
    let c = Helpers.getClientAbbr(client.organization)
    return `${c} ${p}`
  }, [client, project])
  const handleClick = () => {
    onClick()
  }

  return (
    <Grid item>
      <BaseButton variant='rounded' text={label} style={{ fontSize: '10px' }} onClick={handleClick} icon='add' leftIcon />
    </Grid>
  )
}

const RequiredIndicator = () => {
  return <span style={{ color: 'red' }}> *</span>
}

interface MADROPPROPS {
  defaultValue?: string
  disabled?: boolean
  error?: string | boolean
  fullWidth?: boolean
  items: DropDownItem[]
  itemPlaceholder?: string
  label?: string
  labelClassName?: string
  labelColor?: string
  name?: string
  onSelect?: (selected: string, item: DropDownItem) => void
  optional?: boolean
  placeholder?: string
  textPrefix?: string
}

const MADROP = (props: MADROPPROPS) => {
  const [selected, setSelection] = useState<string>(props.defaultValue || '')
  const label = !!props.label ? props.label + (props.error ? ': ' + props.error : '') : false

  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation()
    let val = event.target.value as string
    setSelection(val)
    if (props.onSelect) {
      let item = props.items.filter(el => el.label === val)[0]
      props.onSelect(item.label, item)
    }
  }

  useEffect(() => {
    if (props.defaultValue !== selected) {
      setSelection(props.defaultValue || '')
    }
  }, [props.defaultValue])

  return (
    <div style={{ display: 'block', width: props.fullWidth ? '100%' : '194px' }}>
      {!!label && (
        <div className='input-label' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <p className='input-label' style={{ color: props.error ? '#FF0000' : 'var(--app-text-dark)' }}>{label}{!props.optional && (<RequiredIndicator />)}</p>
          {props.optional && <p className='subtle'>Optional</p>}
        </div>
      )}
      <Select
        defaultValue={selected}
        disabled={props.disabled}
        disableUnderline={true}
        placeholder={props.placeholder}
        margin='dense'
        onClick={e => e.stopPropagation()}
        onChange={handleChange}
        style={{
          width: props.fullWidth ? '100%' : '194px',
          height: '2.5em',
          border: '1px solid var(--theme-dark-grey)',
          backgroundColor: '#ffffff',
          borderRadius: '0.5em',
          paddingLeft: 10
        }}
        value={selected}
        variant='standard'
      >
        {
          props.items?.length > 0
            ? props.items.map(el => (
              <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
            ))
            : <MenuItem disabled key='' value=''>{props.itemPlaceholder}</MenuItem>
        }
      </Select>
    </div>
  )
}
