import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { SnackbarContent } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const persistor = persistStore(store)
const notistackRef = React.createRef<SnackbarProvider>()

const onClose = (key: SnackbarKey) => {
    notistackRef?.current?.closeSnackbar(key)
}

ReactDOM
    .createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SnackbarProvider
                        ref={notistackRef}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        autoHideDuration={3000}
                        content={(key, message) => (
                            <div key={key} style={{ maxWidth: '400px', display: 'flex' }}>
                                <SnackbarContent
                                    action={<CloseRoundedIcon htmlColor='var(--typography-color-dark)' onClick={() => onClose(key)} />}
                                    message={message}
                                    style={{
                                        backgroundColor: 'var(--theme-background)',
                                        color: 'var(--typography-color-dark)',
                                    }}
                                />
                            </div>
                        )}
                        maxSnack={4}
                    >
                        <App />
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    )
